<template>
  <div class="page-content">
    <b-alert class="d-md-none" show variant="warning">{{ t('こちらの機能は現在、パソコンでのみご利用いただけます。') }}</b-alert>

    <!-- PC -->
    <div class="d-none d-md-block">
      <div v-if="enableAibuy === false" class="d-flex container">
        <manual-link>{{ t('1688 AiBUY') }}</manual-link>
      </div>
      <div v-if="enableAibuy === false" class="container guidance-content bg-white p-4">
        <div class="userinfo-title">{{ t('AiBUY（1688向け商品リサーチおよび注文サービス）のご案内') }}</div>
        <div class="px-4 py-2 border mb-2">
          <p class="m-0">
            {{ t('AiBUY（1688向け商品リサーチおよび注文サービス）は、1688が商品データを提供し、RAKUMART株式会社が運営するサービスです。') }}
          </p>
          <p class="m-0">
            {{ t('ご利用にあたっては、利用規約およびプライバシーポリシーをご確認いただき、同意の上ご利用ください。') }}
          </p>
          <div class="text-center">
            <div class='pt-2'>
              <input type="checkbox" v-model="checkTerms" />
              <small class="terms"><label>{{ t('checklabelen') }}
              <a :href="pageTerms" target="_blank"> {{ t('利用規約') }}</a>
              {{ t('および') }}
              <a :href="pagePrivacyPolicy" target="_blank"> {{ t('プライバシーポリシー') }}</a>
              {{ t('checklabelja') }}</label></small>
            </div>
            <b-button @click="confirm" class="btn-sm bg-eresa" :disabled="!checkTerms">{{ t('利用規約およびプライバシーポリシーに同意してAiBUYを有効化する') }}</b-button>
          </div>
        </div>
      </div>

      <template v-if="enableAibuy === true">
        <b-alert class="small text-center" show variant="primary">{{ t('AiBUY（1688向け商品リサーチおよび注文サービス）は、1688が商品データを提供し、RAKUMART株式会社が運営するサービスです。') }}</b-alert>
        <iframe ref="aibuyFrame" width="100%" height="800px" frameborder="0"></iframe>
      </template>

      <loading-screen v-if="isProcessing"></loading-screen>
    </div>
  </div>
</template>

<script>
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import LoadingScreen from '@/components/LoadingScreen.vue';
import ManualLink from '@/components/ManualLink.vue';
import AuthUtil from '@/mixins/authutil';
import Utils from '@/mixins/utils';

const PAGE_TERMS = 'https://www.1688aibuy.net/membershipUtilizationProtocol';
const PAGE_COM_TERMS = 'https://www.1688aibuy.net/membershipUtilizationProtocol';
const PAGE_PRIVACY_POLICY = 'https://www.1688aibuy.net/privacyPolicy';
const PAGE_COM_PRIVACY_POLICY = 'https://www.1688aibuy.net/privacyPolicy';

export default {
  name: 'Aibuy',
  components: {
    LoadingScreen,
    ManualLink,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      pageTerms: this.isComEresa() ? PAGE_COM_TERMS : PAGE_TERMS,
      pagePrivacyPolicy: this.isComEresa() ? PAGE_COM_PRIVACY_POLICY : PAGE_PRIVACY_POLICY,
      enableAibuy: null,
      checkTerms: false,
      isProcessing: false,
    }
  },
  async mounted() {
    this.enableAibuy = !(await this.isFirstAccess());
    if (this.enableAibuy) {
      await this.show();
    }
    await this.validateSubscriber();
  },
  methods: {
    async show() {
      this.isProcessing = true;
      try {
        let url;
        if (this.$route.query.image == void 0) {
          const query = await API.graphql(graphqlOperation(mutations.aibuyOperation, { operation: 'getMenu' }));
          url = JSON.parse(query.data.aibuyOperation).data.main_url;
        }
        else {
          const image_url = `https://images-na.ssl-images-amazon.com/images/I/${this.$route.query.image}`;
          const query = await API.graphql(graphqlOperation(mutations.aibuyOperation, {
            operation: 'imageSearchUrl',
            args: JSON.stringify({ image_url })
          }));
          url = JSON.parse(query.data.aibuyOperation).data.imageSearchUrl;
        }
        this.$refs.aibuyFrame.src = url;
      }
      finally {
        this.isProcessing = false;
      }
    },
    async isFirstAccess() {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const query = await API.graphql(graphqlOperation(queries.getUserInfo, { owner: cognitoUser.username }));
      const userInfo = query.data.getUserInfo;
      return userInfo.aibuyUserId == void 0;
    },
    async confirm() {
      this.enableAibuy = true;
      await this.show();
    }
  }
}
</script>
<style scoped>
.desc-title {
  font-weight: bold;
  border-bottom: solid 1px #ccc;
  margin-top: 4px;
  margin-bottom: 4px;
}

.userinfo-title {
  font-weight: bold;
  background-color: #376f99;
  color: #fff;
  border-bottom: solid 1px #ccc;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.guidance-content {
  font-size: 0.8rem;
}

.terms {
  font-size: 10pt;
}

@media (max-width: 575.98px) {
  .guidance-content {
    font-size: 0.75rem;
  }
}
</style>
