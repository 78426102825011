<template>
  <div class="page-content">
    <iframe ref="memberSiteFrame" width="100%" height="800px" frameborder="0"></iframe>
  </div>
</template>

<script>
import AuthUtil from '@/mixins/authutil';
import Utils from '@/mixins/utils';

const PAGE_URL = 'https://pro.eresa.jp/member/';
const PAGE_URL_COM = 'https://pro.eresa.jp/member/';

export default {
  name: 'MemberSite',
  components: {
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      pageUrl: this.isComEresa() ? PAGE_URL_COM : PAGE_URL,
    }
  },
  async mounted() {
    await this.show();
    await this.validateSubscriber();
  },
  methods: {
    async show() {
      this.$refs.memberSiteFrame.src = this.pageUrl;
    },
  }
}
</script>
<style scoped>
</style>
