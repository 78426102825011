<template>
  <div class="container">
    <h1
      v-if="item.title"
      class="product-title p-3 rounded-lg mb-4 mb-md-5"
    >
      <div class="d-none d-md-flex flex-wrap"> <!-- desktop -->
        <div v-if="subscriber == true && item.isDanger" class="text-eresa mr-2 text-danger">
          <b-icon icon="exclamation-triangle"></b-icon>
        </div>
        <a v-if="nolink == void 0" role="button" rel="noreferrer" target="_blank" :href="`https://www.amazon${getDomainName(item.domainId)}/dp/${item.asin}/ref=nosim`" class="text-eresa" style="text-decoration: underline">
          {{ item.title }}
          <b-icon icon="box-arrow-up-right" class="ml-2"></b-icon>
        </a>
        <div v-else class="text-eresa">{{ item.title }}</div>
        <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white" @click="analysisTitle">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
        <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2"></lock-pro-button>
      </div>
      <div class="d-flex d-md-none flex-wrap">
        <div>
          <b-icon v-if="subscriber == true && item.isDanger" icon="exclamation-triangle" class="text-danger mr-2"></b-icon>
          <a v-if="nolink == void 0" role="button" rel="noreferrer" target="_blank" :href="`https://www.amazon${getDomainName(item.domainId)}/dp/${item.asin}/ref=nosim`" class="text-eresa" style="text-decoration: underline">
            {{ item.title }}
            <b-icon icon="box-arrow-up-right" class="ml-2"></b-icon>
          </a>
          <div v-else class="text-eresa">{{ item.title }}</div>
        </div>
        <div v-if="subscriber === true" class="mt-2"><b-button size="sm" class="btn bg-eresa text-white" @click="analysisTitle">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
        <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="mt-2"></lock-pro-button>
      </div>
    </h1>
    <div v-if="item.title" class="row cloak">
      <div class="col-12 grid-container">
        <!-- 商品詳細 start -->
        <div class="row mb-5" :style="this.displaySettings['detail']">
          <div class="col-12 col-md-4 mb-4 mb-md-0">
            <div>
              <image-changer :item="item" :favorite="nolink == void 0 || login != void 0 ? 'favorite' : undefined" :flag="nolink == void 0 ? 'flag' : undefined" @flagClicked="flagClicked"></image-changer>
              <div class="d-none d-md-flex flex-wrap">
                <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white" @click="searchImage(item)">{{ t('1688で検索') }}</b-button></div>
                <lock-pro-button v-else-if="subscriber === false" text="1688で検索" class="ml-2"></lock-pro-button>
              </div>
            </div>
            <div>
              <product-info class="mt-2" ref="productInfo" :nolink="nolink" :item="item" :subscriber="subscriber" :disableAI="true" @aiclick="productInfoAianalysis"></product-info>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <span v-if="isPremier(item)" class="p-1 text-white mr-2 tagpremier">{{ t('プレミア') }}</span>
            <span v-if="sizeType != ''" class="p-1 text-white mr-2 tagsize">{{ sizeType }}</span>
            <span v-if="isLowerCommisionItem(item)" class="p-1 text-white mr-2 taglower">{{ t('特別販売手数料（5%）') }}</span>
            <span v-if="isSmallAndLight(item)" class="p-1 text-white mr-2 tagsal">{{ t('小型軽量') }}</span>
            <span v-if="item.productType != void 0 && item.productType == 5" class="p-1 mr-2 tagparent">{{ t('親ASIN') }}</span>
            <span v-if="item._pv != void 0" style="color: #777"><font-awesome-icon :icon="['fas', 'eye']" class="ml-2 mr-1" :title="t('過去1ヶ月間で{0}回照会されました。', [item._pv])" />{{ item._pv }}</span>
            <div class="mt-1 mb-4 mb-md-0" v-if="!item.features">
              <template v-if="item.description">
                <p v-if="showDescription">{{ item.description }}</p>
                <p v-else>
                  {{ item.description.substr(0, 100) }}
                  <b-button v-if="item.description.length > 100" variant="link" @click="showDescription = true">{{ t('…続きを読む▼') }}</b-button>
                </p>
              </template>
            </div>
            <div v-else class="mt-1">
              <template v-if="showDescription">
                <p v-for="(feature, i) in item.features" :key="i">
                  {{ feature }}
                </p>
              </template>
              <template v-else>
                <p>{{ item.features[0] }}</p>
                <b-button v-if="item.features.length > 1" variant="link" @click="showDescription = true">{{ t('…続きを読む▼') }}</b-button>
              </template>
            </div>

            <ranking-drop ref="rankingDrop" :item="item" :subscriber="subscriber" class="mt-3" @loaded="rankingDropLoaded"></ranking-drop>
            <price-table :item="item" class="mt-3" :subscriber="subscriber" :nolink="nolink" bbchina></price-table>
            <div v-if="nolink == void 0" class="text-center">
              <router-link v-if="subscriber === true" :to="{ name: 'Tracking', params: { asin: item.asin }, query: { domain } }" v-slot="{ href }" class="mt-2">
                <a role="button" :href="href" class="btn bg-eresa text-sm text-white font-weight-bold mb-2 px-4 mr-1">{{ t('商品のトラッキング') }}</a>
              </router-link>
              <div v-else-if="subscriber === false" class="mt-2 locktitle">
                <a role="button" class="btn bg-eresa text-sm text-white font-weight-bold mb-2 px-4 mr-1" style="opacity: 0.6">{{ t('商品のトラッキング（ベータ版）') }}<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></a>
                <lock-pro :nolink="nolink" noicon></lock-pro>
              </div>
            </div>
            <shop-links v-if="nolink == void 0" :subscriber="subscriber" :item="item" class="border-top"></shop-links>
            <product-ai-buttons :subscriber="subscriber" :item="item"></product-ai-buttons>
          </div>
        </div>
        <!-- 商品詳細 end -->

        
        <!-- バリエーション start -->
        <template v-if="nolink == void 0">
          <variation-list :subscriber="subscriber" :item="item" :style="displaySettings['variation-list']" class="grid-hr"></variation-list>
        </template>
        <!-- バリエーション end -->

        <!-- 出品者一覧 start -->
        <product-seller-list ref="productSellerList" :item="item" :nolink="nolink" :disabled="true" :style="displaySettings['product-seller']" class="grid-hr"></product-seller-list>
        
        <!-- 出品者一覧 end  -->

        <!-- 他ショップ start -->
        <template v-if="noshoplist == void 0">
          <shop-list :item="item" :style="displaySettings['shop-list']" class="grid-hr"></shop-list>
        </template>
        <!-- 他ショップ end -->
        <!--
        <div class="d-flex flex-column text-right" style="align-items: center">
          <img class="keepa-graph mt-3" :src="keepaGraphUrl" alt />
        </div>
        -->
        <!-- カート取得率 start -->
        <buy-box-rate :subscriber="subscriber" :item="item" :nolink="nolink" class="grid-hr" :style="displaySettings['buy-box-rate']"></buy-box-rate>
        <!-- カート取得率 end -->

        <!-- 推移グラフ start -->
        <div :style="displaySettings['chart']" class="grid-hr">
          <div v-if="subscriber !== true && nolink == void 0 && !isComEresa()" ref="ads" class="ads"></div>
          <b-row>
            <b-col cols="6">
              <b-nav tabs :class="isMobile ? 'small font-weight-bold' : ''">
                <b-nav-item
                  @click="changeChartDataType(CHART_DATA_TYPES.ALL)"
                  :active="chartDataType === CHART_DATA_TYPES.ALL"
                  >{{ t('すべて') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataType(CHART_DATA_TYPES.NEW)"
                  :active="chartDataType === CHART_DATA_TYPES.NEW"
                  >{{ t('新品') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataType(CHART_DATA_TYPES.USED)"
                  :active="chartDataType === CHART_DATA_TYPES.USED"
                  >{{ t('中古') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataType(CHART_DATA_TYPES.COLLECTOR)"
                  :active="chartDataType === CHART_DATA_TYPES.COLLECTOR"
                  >{{ t('コレクター') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataType(CHART_DATA_TYPES.RANKING)"
                  :active="chartDataType === CHART_DATA_TYPES.RANKING"
                  >{{ t('ランキング') }}</b-nav-item
                >
              </b-nav>
            </b-col>
            <b-col cols="6">
              <b-nav pills :class="isMobile ? 'small font-weight-bold' : ''">
                <b-nav-item
                  @click="changeChartDataTerm(CHART_DATA_TERMS.MON1)"
                  :active="chartDataTerm === CHART_DATA_TERMS.MON1"
                  >{{ t('1ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataTerm(CHART_DATA_TERMS.MON3)"
                  :active="chartDataTerm === CHART_DATA_TERMS.MON3"
                  :disabled="!isDetail"
                  >{{ t('3ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataTerm(CHART_DATA_TERMS.MON6)"
                  :active="chartDataTerm === CHART_DATA_TERMS.MON6"
                  :disabled="!isDetail"
                  id="graph-6"
                  >{{ t('6ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataTerm(CHART_DATA_TERMS.MON12)"
                  :active="chartDataTerm === CHART_DATA_TERMS.MON12"
                  :disabled="!isDetail"
                  id="graph-12"
                  >{{ t('12ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeChartDataTerm(CHART_DATA_TERMS.ALL)"
                  :active="chartDataTerm === CHART_DATA_TERMS.ALL"
                  :disabled="!isDetail"
                  id="graph-all"
                  >{{ t('全期間') }}</b-nav-item
                >
              </b-nav>
            </b-col>
          </b-row>
          <!-- <div class="chartStyle" ref="chartdiv"></div> -->
          <line-chart
            ref="priceChart"
            class="chartStyle"
            :chart-data="chartData['最安値']"
            :domain="item.domainId"
            hasRight
          ></line-chart>
          <div class="chartAverage mb-2">
            <span>{{ t('平均') }}</span>
            <span v-for="(average, key) in chartAverages['最安値']" :key="key"  class="ml-2" :style="{color:average['color']}">
                    {{ t(key) }}:{{average['value']}}
            </span>
          </div>
          <div class="chartAverage">
            <span v-if="subscriber === true" class="mr-2">{{ t('売上個数予測') }}
              {{ t('週間') }}: {{ salesEstimations.weekly }}
              {{ t('月間') }}: {{ salesEstimations.monthly }}
            </span>
            <span v-else-if="subscriber === false" class="mr-2 locktitle">{{ t('売上個数予測') }}
              <font-awesome-icon icon="fa-solid fa-lock" style="color: #000;" />
              <lock-pro :nolink="nolink" noicon></lock-pro>
            </span>
          </div>
          <div v-if="subscriber === true" class="chartAverage mb-2">
            <span v-if="salesEstimations.monthly === '-'">
              {{ t('※ランキング降下回数をご参考ください。') }}
            </span>
            <span v-if="salesEstimations.monthly !== '-'">
              {{ t('※こちらの数値は過去のデータを元に算出しております。一つの参考として活用ください。') }}
            </span>
          </div>
          <line-chart
            class="chartStyle"
            :chart-data="chartData['出品数']"
          ></line-chart>
          <div class="chartAverage mb-2">
            <span>{{ t('平均') }}</span>
            <span v-for="(average, key) in chartAverages['出品数']" :key="key"  class="ml-2" :style="{color:average['color']}">
                    {{ t(key) }}:{{average['value']}}
            </span>
          </div>
        </div>
        <!-- 推移グラフ end -->

        <!-- 日別データ推移 start -->
        <div class="table-responsive historicalDataTable" ref="historicalDataTable" :style="displaySettings['historical-data-table']">
          <b-row class="pb-2">

            <b-col cols="6">
              <b-nav tabs :class="isMobile ? 'small font-weight-bold' : ''">
                <b-nav-item
                  @click="changeHistoricalDataType(HISTORICAL_DATA_TYPES.DEFAULT)"
                  :active="historicalDataType === HISTORICAL_DATA_TYPES.DEFAULT"
                  >{{ t('最安値') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataType(HISTORICAL_DATA_TYPES.NEW)"
                  :active="historicalDataType === HISTORICAL_DATA_TYPES.NEW"
                  >{{ t('新品') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataType(HISTORICAL_DATA_TYPES.USED)"
                  :active="historicalDataType === HISTORICAL_DATA_TYPES.USED"
                  >{{ t('中古') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataType(HISTORICAL_DATA_TYPES.COLLECTOR)"
                  :active="historicalDataType === HISTORICAL_DATA_TYPES.COLLECTOR"
                  >{{ t('コレクター') }}</b-nav-item
                >
              </b-nav>
            </b-col>

            <b-col cols="6">
              <b-nav pills :class="isMobile ? 'small font-weight-bold' : ''">
                <b-nav-item
                  @click="changeHistoricalDataTerm(HISTORICAL_DATA_TERMS.MON1)"
                  :active="historicalDataTerm === HISTORICAL_DATA_TERMS.MON1"
                  >{{ t('1ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataTerm(HISTORICAL_DATA_TERMS.MON3)"
                  :active="historicalDataTerm === HISTORICAL_DATA_TERMS.MON3"
                  :disabled="!isDetail"
                  >{{ t('3ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataTerm(HISTORICAL_DATA_TERMS.MON6)"
                  :active="historicalDataTerm === HISTORICAL_DATA_TERMS.MON6"
                  :disabled="!isDetail"
                  id="ranking-6"
                  >{{ t('6ヶ月') }}</b-nav-item
                >
                <b-nav-item
                  @click="changeHistoricalDataTerm(HISTORICAL_DATA_TERMS.MON12)"
                  :active="historicalDataTerm === HISTORICAL_DATA_TERMS.MON12"
                  :disabled="!isDetail"
                  id="ranking-12"
                  >{{ t('12ヶ月') }}</b-nav-item
                >
              </b-nav>
            </b-col>
          </b-row>
          <table :class="isMobile ? 'table-sm small' : 'table'">
            <thead class="bg-gray header-top">
              <tr class="text-center text-secondary">
                <th scope="col">{{ t('日付') }}</th>
                <th scope="col">{{ t('ランキング') }}</th>
                <template v-if="historicalDataType === HISTORICAL_DATA_TYPES.NEW">
                  <th scope="col">{{ t('新品出品数') }}</th>
                  <th scope="col">{{ t('プライム') }}</th>
                  <th scope="col">{{ t('プライム以外') }}</th>
                  <th scope="col">{{ t('Amazon価格') }}</th>
                  <th scope="col">{{ t('カート価格') }}</th>
                </template>
                <template v-else-if="historicalDataType === HISTORICAL_DATA_TYPES.USED">
                  <th scope="col">{{ t('中古出品数') }}</th>
                  <th scope="col">{{ t('ほぼ新品') }}</th>
                  <th scope="col">{{ t('非常に良い') }}</th>
                  <th scope="col">{{ t('良い') }}</th>
                  <th scope="col">{{ t('可') }}</th>
                </template>
                <template v-else-if="historicalDataType === HISTORICAL_DATA_TYPES.COLLECTOR">
                  <th scope="col">{{ t('コレクター出品数') }}</th>
                  <th scope="col">{{ t('ほぼ新品') }}</th>
                  <th scope="col">{{ t('非常に良い') }}</th>
                  <th scope="col">{{ t('良い') }}</th>
                  <th scope="col">{{ t('可') }}</th>
                </template>
                <template v-else>
                  <th scope="col">{{ t('新品最安値') }}</th>
                  <th scope="col">{{ t('新品出品数') }}</th>
                  <th scope="col">{{ t('中古最安値') }}</th>
                  <th scope="col">{{ t('中古出品数') }}</th>
                </template>
              </tr>
            </thead>
            <thead class="bg-gray header-bottom">
              <tr class="text-right text-secondary">
                <th scope="row">{{ t('（表示価格は全て送料込みとなっております。）') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center text-lg py-5"
                v-for="(row, i) in historical"
                :key="i"
              >
                <td
                  scope="row"
                  class="align-middle"
                  :class="isMobile ? 'text-sm' : ''"
                >
                  {{ row["日付"] }}
                </td>
                <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['ランキング_downper5'] ? 'font-weight-bold' : ''}`">
                  {{ row["ランキング"] | numberString }}
                </td>
                <template v-if="historicalDataType === HISTORICAL_DATA_TYPES.NEW">
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['新品出品数_down'] ? 'font-weight-bold' : ''}`">
                    {{ row["新品出品数"] | numberString }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['プライム_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["プライム"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['プライム以外（送料込）_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["プライム以外（送料込）"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['Amazon価格_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["Amazon価格"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['カート価格_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["カート価格"]) }}
                  </td>
                </template>
                <template v-else-if="historicalDataType === HISTORICAL_DATA_TYPES.USED">
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['中古出品数_down'] ? 'font-weight-bold' : ''}`">
                    {{ row["中古出品数"] | numberString }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['ほぼ新品_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["ほぼ新品"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['非常に良い_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["非常に良い"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['良い_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["良い"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['可_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["可"]) }}
                  </td>
                </template>
                <template v-else-if="historicalDataType === HISTORICAL_DATA_TYPES.COLLECTOR">
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['中古出品数_down'] ? 'font-weight-bold' : ''}`">
                    {{ row["コレクター出品数"] | numberString }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['ほぼ新品_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["ほぼ新品"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['非常に良い_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["非常に良い"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['良い_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["良い"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['可_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["可"]) }}
                  </td>
                </template>
                <template v-else>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['新品最安値_up'] ? 'text-primary font-weight-bold' : ''}`">
                    {{ price(row["新品最安値"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['新品出品数_down'] ? 'font-weight-bold' : ''}`">
                    {{ row["新品出品数"] | numberString }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['中古最安値_up'] ? 'text-danger font-weight-bold' : ''}`">
                    {{ price(row["中古最安値"]) }}
                  </td>
                  <td class="align-middle" :class="`${isMobile ? 'text-sm' : ''} ${row['中古出品数_down'] ? 'font-weight-bold' : ''}`">
                    {{ row["中古出品数"] | numberString }}
                  </td>
                </template>
              </tr>
            </tbody>
            <!-- <tfoot class="bg-gray footer">
              <tr class="text-right">
                <th scope="row" v-if="subscriber === true"><b-button size="sm" class="btn bg-eresa text-white" :disabled="historicalDataType == HISTORICAL_DATA_TYPES.COLLECTOR || historicalDataTerm === HISTORICAL_DATA_TERMS.MON6 || historicalDataTerm === HISTORICAL_DATA_TERMS.MON12 || disableHistoricalAI" @click="aianalysis">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></th>
                <th scope="row" v-else-if="subscriber === false"><lock-pro-button text="AI分析" icon="fa-solid fa-brain" class="ml-2"></lock-pro-button></th>
              </tr>
            </tfoot> -->
          </table>
        </div>
        <!-- 日別データ推移 end -->
      </div>
    </div>
    <div v-else>{{ t('商品が見つかりません。') }}</div>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import moment from "moment";
import _ from "lodash";
import LockPro from "@/components/LockPro.vue";
import LockProButton from "@/components/LockProButton.vue";
import LineChart from "@/components/LineChart";
import AiChatWindow from "@/components/AiChatWindow";
import ImageChanger from "@/components/Product/ImageChanger";
import RankingDrop from "@/components/Product/RankingDrop";
import ProductInfo from "@/components/Product/ProductInfo";
import PriceTable from "@/components/Product/PriceTable";
import ShopLinks from "@/components/Product/ShopLinks";
import ProductAiButtons from "@/components/Product/ProductAiButtons";
import BuyBoxRate from "@/components/Product/BuyBoxRate";
import ShopList from "@/components/Product/ShopList";
import VariationList from "@/components/Product/VariationList";
import ProductSellerList from "@/components/Product/ProductSellerList";
import Utils from "@/mixins/utils";
import AuthUtil from "@/mixins/authutil";
import { AIChat } from "@/mixins/AIChat";
import axios from "axios";
import * as palette from "google-palette";
import ProductBlocks from "@/mixins/ProductBlocks";

const CHART_DATA_TYPES = ["ALL", "NEW", "USED", "COLLECTOR", "RANKING"].reduce(
  (m, v) => Object.assign(m, { [v]: v }),
  {}
);
const CHART_DATA_TERMS = ["MON1", "MON3", "MON6", "MON12", "ALL"].reduce(
  (m, v) => Object.assign(m, { [v]: v }),
  {}
);
const HISTORICAL_DATA_TYPES = ["DEFAULT", "NEW", "USED", "COLLECTOR"].reduce(
  (m, v) => Object.assign(m, { [v]: v }),
  {}
);
const HISTORICAL_DATA_TERMS = ["MON1", "MON3", "MON6", "MON12", "ALL"].reduce(
  (m, v) => Object.assign(m, { [v]: v }),
  {}
);
const RANKING_COLORINDEX = 6;
const RANKING_COLOR = '#af8d00';

export default {
  name: "Product",
  components: {
    LockPro,
    LockProButton,
    LineChart,
    AiChatWindow,
    RankingDrop,
    ImageChanger,
    ProductInfo,
    PriceTable,
    ShopLinks,
    ProductAiButtons,
    BuyBoxRate,
    ShopList,
    VariationList,
    ProductSellerList,
  },
  props: [
    "item",
    "csv",
    "isDetail",
    "keepaGraphUrl",
    "nolink",
    'noshoplist',
    'login',
  ],
  data() {
    return {
      asin: null,
      historical: null,
      salesRankCat: null,
      slide: 0,
      sliding: null,
      visibleFeature: false,
      CSV_NAME_INDEXES: this.getCsvNameIndexes(),
      CHART_DATA_TYPES,
      CHART_DATA_TERMS,
      HISTORICAL_DATA_TYPES,
      HISTORICAL_DATA_TERMS,
      chartDataType: CHART_DATA_TYPES.ALL,
      chartDataTerm: CHART_DATA_TERMS.MON3,
      historicalDataType: HISTORICAL_DATA_TYPES.DEFAULT,
      historicalDataTerm: HISTORICAL_DATA_TERMS.MON1,
      chartData: { 最安値: {}, 出品数: {}, ランキング: {} },
      chartOptions: null,
      isMobile: this.$store.getters.isMobile,
      fbaFee: 0,
      domain: this.isDefaultDomain(this.$store.getters.getDomain) ? void 0 : this.$store.getters.getDomain,
      subscriber: null,
      showDescription: false,
      chartAverages : {最安値: {}, 出品数: {}, ランキング: {}},
      salesEstimations: { weekly: '-', monthly: '-'},
      disableHistoricalAI: true,
      displaySettings: {},
    };
  },
  mixins: [Utils, AuthUtil],
  filters: {
    numberString(val) {
      return `${val != void 0 && val >= 0 ? Number(val).toLocaleString() : "-"}`;
    },
  },
  async created() {
  },
  async mounted() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
    //console.log(this.item)
    if(this.subscriber){
      await this.setDisplayOrders();
      await this.setChartSettings();
    }
    
    // 表示ちらつき対応
    const cloaks = document.getElementsByClassName("cloak");
    for (let i = 0; i < cloaks.length; i++) {
      cloaks[i].classList.remove("cloak");
    }
    
    this.asin = this.item.asin;
    this.setProduct(this.item).then();
    this.showChart(this.chartDataType, this.chartDataTerm);

    if (this.subscriber !== true && this.nolink == void 0) {
      const iframe = document.createElement("iframe");
      this.$refs.ads.appendChild(iframe);
      const html = `<body>${(await axios.get("/ads.txt")).data}</body>`;
      //console.log(await axios.get('/ads.txt').data)
      const iframeDocument = iframe.contentWindow.document;
      iframeDocument.open();
      iframeDocument.write(html);
      iframeDocument.close();
    }
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  watch: {
    item() {
      this.setProduct(this.item).then();
    },
    csv() {
      this.showChart(this.chartDataType, this.chartDataTerm);
    },
    computedIsMobile(val) {
      this.isMobile = val;
    },
  },
  computed: {
    sizeType() {
      return this.getSizeTypeString(this.item);
    },
    computedIsMobile() {
      return this.$store.getters.isMobile;
    },
  },
  methods: {
    async setDisplayOrders() {
      const savedDisplayOrders = await this.$store.getters.getDisplayOrders;
      //設定が存在する場合は設定を反映
      if (savedDisplayOrders && savedDisplayOrders.visibleItems && savedDisplayOrders.hiddenItems) {

        const defaultOrders = ProductBlocks.getBlocksInOrder();

        defaultOrders.forEach((block) => {
          const id = block.id;
          if (savedDisplayOrders.visibleItems[id] != void 0) {
            this.displaySettings[id] = {
               order:savedDisplayOrders.visibleItems[id]
            };
          } else if (savedDisplayOrders.hiddenItems[id] != void 0) {
            this.displaySettings[id] = {
              display: "none"
            };
          }

        });
      }
      //設定が存在しない場合は初期値を反映
      else {
        const defaultOrders = ProductBlocks.getBlocksInOrder();
        defaultOrders.forEach((block) => {
          const id = block.id;
          this.displaySettings[id] = {
            order: block.order,
          };
        });
      }
    },
    async setChartSettings() {
      const productDetailSettings = await this.$store.getters.getProductDetailSettings;
      
      if(productDetailSettings?.defaultChartDataType) {
        this.chartDataType = productDetailSettings.defaultChartDataType;
      }
      if(productDetailSettings?.defaultChartDataTerms) {
        this.chartDataTerm = productDetailSettings.defaultChartDataTerms;
      }
      if(productDetailSettings?.defaultHistoricalDataType) {
        this.historicalDataType = productDetailSettings.defaultHistoricalDataType;
      }
      if(productDetailSettings?.defaultHistoricalDataTerms) {
        this.historicalDataTerm = productDetailSettings.defaultHistoricalDataTerms;
      }
    },
    getImageSrc(imageFile) {
      return `https://images-na.ssl-images-amazon.com/images/I/${imageFile}`;
    },
    async setProduct(item) {
      if (!this.item || !Object.keys(this.item).length) return;
      item.jan = item.eanList ? item.eanList[0] : "-";
      item.titleEuc = this.convertEncoding(item.title, "EUCJP");
      item.titleSjis = this.convertEncoding(item.title, "SJIS");
      this.salesRankCat = null;
      const km90 = moment().add(-90, "days").unix() * 1000 / 60000 - 21564000;
      if (item.salesRankReference < 0 && item.categoryTree != void 0) {
        //メインのランキングが利用不可の場合はsalesRanksの情報に読み替える
        const bestIndex = item.categoryTree.map(c => {
          const salesRank = item.salesRanks == void 0 ? void 0 : item.salesRanks[c.catId];
          return salesRank == void 0 ? -1 : _.last(_.flatten(_.chunk(salesRank, 2).filter(s => s[0] >= km90).filter(s => s[1] > 0))) ?? -1;
        }).reduce((p, e, i) => p < 0 ? (e < 0 ? -1 : i) : p, -1);
        if (bestIndex >= 0) {
          this.salesRankCat = item.categoryTree[bestIndex];
          item.csv[this.CSV_NAME_INDEXES.SALES] = item.salesRanks[this.salesRankCat.catId];
        }
      }
      this.historical = this.getHistoricalData(
        item.csv,
        this.historicalDataType,
        this.historicalDataTerm
      );
      this.disableHistoricalAI = false;
      this.fbaFee = item.fbaFees == void 0 ? 0 : Object.values(item.fbaFees).reduce((s, v) => s + v, 0);
    },
    convertCsv(csv, titleArr, isDetail) {
      const data = {};
      for (let [key, idx] of titleArr) {
        const arr =
          _.inRange(
            idx,
            this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING,
            this.CSV_NAME_INDEXES.EBAY_USED_SHIPPING
          ) || idx === this.CSV_NAME_INDEXES.NEW_FBM_SHIPPING
            ? _.chunk(csv[idx], 3)
            : _.chunk(csv[idx], 2);
        //const arr = idx === this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING ? _.chunk(csv[idx], 3) : _.chunk(csv[idx], 2);
        arr
          .forEach(([d, v, s]) => {
            const date = moment(this.getDateFromKeepaTime(d)).format(
              `YYYY-MM-DD${isDetail ? " HH:mm:ss" : ""}`
            );
            data[date] = data[date] || {};
            const value = Number(v) + (s ? Number(s) : 0);
            if (data[date][key] == void 0 || value >= 0) {
              data[date][key] = value;
            }
            data[date][`${key}_last`] = value;
          });
      }
      let lastVal = {};
      for (let date of Object.keys(data).sort((a, b) =>
        moment(a).diff(moment(b))
      )) {
        for (let [key] of titleArr) {
          const v = data[date][key];
          data[date][`${key}_downper5`] = false; //5%降下
          data[date][`${key}_up`] = false; //値上昇
          data[date][`${key}_down`] = false; //値降下
          if (v == void 0) {
            //データなしの場合は前日データを引き継ぐ（前日の最新が未設定の場合は当日も未設定にする）
            if (lastVal[`${key}_last`] < 0) {
              data[date][key] = null;
            }
            else {
              data[date][key] = lastVal[key] >= 0 ? lastVal[key] : null;
            }
          }
          else if (v < 0) {
            //データありで未設定の場合はグラフ表示しない
            data[date][key] = null;
          }
          else if (lastVal[key] != void 0) {
            //データありで前日データありの場合は前日比5%の変動有無を設定
            if (lastVal[key] > 0) {
              data[date][`${key}_downper5`] = data[date][key] <= lastVal[key] * 0.95;
            }
            data[date][`${key}_up`] = data[date][key] > lastVal[key];
            data[date][`${key}_down`] = data[date][key] < lastVal[key];
          }
          lastVal[key] = data[date][key];
          lastVal[`${key}_last`] = data[date][`${key}_last`];
        }
      }
      return data;
    },
    getHistoricalData(csv, dataType, dataTerm) {
      const beforeMonth = moment().add(
        -1 * Number(dataTerm.replace("MON", "")),
        "months"
      );

      const dataTitleIndexes = (() => {
        if (dataType === HISTORICAL_DATA_TYPES.NEW) {
          return [
            ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ["新品出品数", this.CSV_NAME_INDEXES.COUNT_NEW],
            ["プライム", this.CSV_NAME_INDEXES.NEW_FBA],
            ["プライム以外（送料込）", this.CSV_NAME_INDEXES.NEW_FBM_SHIPPING],
            ["Amazon価格", this.CSV_NAME_INDEXES.AMAZON],
            ["カート価格", this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING],
          ];
        }
        else if (dataType === HISTORICAL_DATA_TYPES.USED) {
          return [
            ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ["中古出品数", this.CSV_NAME_INDEXES.COUNT_USED],
            ["ほぼ新品", this.CSV_NAME_INDEXES.USED_NEW_SHIPPING],
            ["非常に良い", this.CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING],
            ["良い", this.CSV_NAME_INDEXES.USED_GOOD_SHIPPING],
            ["可", this.CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING],
          ];
        }
        else if (dataType === HISTORICAL_DATA_TYPES.COLLECTOR) {
          return [
            ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ["コレクター出品数", this.CSV_NAME_INDEXES.COUNT_COLLECTIBLE],
            ["ほぼ新品", this.CSV_NAME_INDEXES.COLLECTIBLE_NEW_SHIPPING],
            ["非常に良い", this.CSV_NAME_INDEXES.COLLECTIBLE_VERY_GOOD_SHIPPING],
            ["良い", this.CSV_NAME_INDEXES.COLLECTIBLE_GOOD_SHIPPING],
            ["可", this.CSV_NAME_INDEXES.COLLECTIBLE_ACCEPTABLE_SHIPPING],
          ];
        }
        else {
          return [
            ["新品最安値", this.CSV_NAME_INDEXES.NEW],
            ["新品出品数", this.CSV_NAME_INDEXES.COUNT_NEW],
            ["中古最安値", this.CSV_NAME_INDEXES.USED],
            ["中古出品数", this.CSV_NAME_INDEXES.COUNT_USED],
            ["ランキング", this.CSV_NAME_INDEXES.SALES],
          ];
        }
      })();
      const data = this.convertCsv(csv, dataTitleIndexes, false);
      //取得日を追加
      return Object.keys(data)
        .filter((k) => moment(k).diff(beforeMonth) > 0)
        .sort((a, b) => -1 * moment(a).diff(moment(b)))
        .map((k) => Object.assign(data[k], { 日付: k }));
    },
    getChartData(csv, titleArr) {
      const data = this.convertCsv(csv, titleArr);
      const allData = {};
      for (let [key] of titleArr) {
        for (let date of Object.keys(data)) {
          if (!allData[date]) allData[date] = {};
          allData[date][key] = data[date][key];
        }
      }
      return Object.keys(allData)
        .sort()
        .map((k) => Object.assign(allData[k], { date: k }));
    },
    changeChartDataType(dataType) {
      this.showChart(dataType, this.chartDataTerm);
      this.chartDataType = dataType;
    },
    changeChartDataTerm(dataTerm) {
      if (!this.isDetail) return;
      this.showChart(this.chartDataType, dataTerm);
      this.chartDataTerm = dataTerm;
      this.$emit('changeChartDataTerm', dataTerm);
    },
    async changeHistoricalDataType(dataType) {
      this.disableHistoricalAI = true;
      await this.sleep(10);
      this.historical = this.getHistoricalData(this.csv, dataType, this.historicalDataTerm);
      this.historicalDataType = dataType;
      this.disableHistoricalAI = false;
    },
    async changeHistoricalDataTerm(dataTerm) {
      if (!this.isDetail) return;
      this.disableHistoricalAI = true;
      await this.sleep(10);
      this.historical = this.getHistoricalData(this.csv, this.historicalDataType, dataTerm);
      this.historicalDataTerm = dataTerm;
      this.disableHistoricalAI = false;
      this.$emit('changeHistoricalDataTerm', dataTerm);
    },
    showChart(dataType, dataTerm) {
      if (!this.csv || !this.csv.length) return;
      const dataTitleIndexes = (() => {
        if (dataType === CHART_DATA_TYPES.NEW) {
          return {
            最安値: [
              ["新品最安値", this.CSV_NAME_INDEXES.NEW],
              ["プライム", this.CSV_NAME_INDEXES.NEW_FBA],
              ["プライム以外（送料込）", this.CSV_NAME_INDEXES.NEW_FBM_SHIPPING],
              ["Amazon価格", this.CSV_NAME_INDEXES.AMAZON],
              ["カート価格", this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING],
              ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ],
            出品数: [["新品出品数", this.CSV_NAME_INDEXES.COUNT_NEW]],
            ランキング: [["ランキング", this.CSV_NAME_INDEXES.SALES]],
          };
        } else if (dataType === CHART_DATA_TYPES.USED) {
          return {
            最安値: [
              ["中古最安値", this.CSV_NAME_INDEXES.USED],
              ["ほぼ新品", this.CSV_NAME_INDEXES.USED_NEW_SHIPPING],
              ["非常に良い", this.CSV_NAME_INDEXES.USED_VERY_GOOD_SHIPPING],
              ["良い", this.CSV_NAME_INDEXES.USED_GOOD_SHIPPING],
              ["可", this.CSV_NAME_INDEXES.USED_ACCEPTABLE_SHIPPING],
              ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ],
            出品数: [["中古出品数", this.CSV_NAME_INDEXES.COUNT_USED]],
            ランキング: [["ランキング", this.CSV_NAME_INDEXES.SALES]],
          };
        } else if (dataType === CHART_DATA_TYPES.COLLECTOR) {
          return {
            最安値: [
              ["ほぼ新品", this.CSV_NAME_INDEXES.COLLECTIBLE_NEW_SHIPPING],
              ["非常に良い", this.CSV_NAME_INDEXES.COLLECTIBLE_VERY_GOOD_SHIPPING],
              ["良い", this.CSV_NAME_INDEXES.COLLECTIBLE_GOOD_SHIPPING],
              ["可", this.CSV_NAME_INDEXES.COLLECTIBLE_ACCEPTABLE_SHIPPING],
            ],
            出品数: [["コレクター出品数", this.CSV_NAME_INDEXES.COUNT_COLLECTIBLE]],
            ランキング: [["ランキング", this.CSV_NAME_INDEXES.SALES]],
          };
        } else if (dataType === CHART_DATA_TYPES.RANKING) {
          return {
            最安値: [
              ["ランキング", this.CSV_NAME_INDEXES.SALES],
            ],
            出品数: [
              ["新品出品数", this.CSV_NAME_INDEXES.COUNT_NEW],
              ["中古出品数", this.CSV_NAME_INDEXES.COUNT_USED],
              ["コレクター出品数", this.CSV_NAME_INDEXES.COUNT_COLLECTIBLE],
            ],
            ランキング: [["ランキング", this.CSV_NAME_INDEXES.SALES]],
          };
        }
        return {
          最安値: [
            ["Amazon価格", this.CSV_NAME_INDEXES.AMAZON],
            ["カート価格", this.CSV_NAME_INDEXES.BUY_BOX_SHIPPING],
            ["新品最安値", this.CSV_NAME_INDEXES.NEW],
            ["中古最安値", this.CSV_NAME_INDEXES.USED],
            ["コレクター最安値", this.CSV_NAME_INDEXES.COLLECTIBLE],
            ["ランキング", this.CSV_NAME_INDEXES.SALES],
          ],
          出品数: [
            ["新品出品数", this.CSV_NAME_INDEXES.COUNT_NEW],
            ["中古出品数", this.CSV_NAME_INDEXES.COUNT_USED],
            ["コレクター出品数", this.CSV_NAME_INDEXES.COUNT_COLLECTIBLE],
          ],
          ランキング: [["ランキング", this.CSV_NAME_INDEXES.SALES]],
        };
      })();
      if (dataType === 'COLLECTOR' || dataType === 'RANKING') {
        this.$refs.priceChart.options.scales.yAxes[1].ticks.fontColor = 'rgba(0, 0, 0, 0)'
      }
      else {
        this.$refs.priceChart.options.scales.yAxes[1].ticks.fontColor = '#666'
      }
      const data = this.convertCsv(
        this.csv,
        _.flatten(Object.values(dataTitleIndexes))
      );
      const colors = palette(
        "mpn65",
        _.flatten(Object.values(dataTitleIndexes)).length
      ).map((hex) => {
        return "#" + hex;
      });
      const minDate =
        dataTerm === CHART_DATA_TERMS.ALL
          ? moment().add(-30, "years")
          : moment().add(-1 * Number(dataTerm.replace("MON", "")), "months");
      const chartData = {};
      let colorIdx = 0;
      const getcolor = (colorIdx, key) => key === 'ランキング' ? RANKING_COLOR : colors[colorIdx];
      for (let chartTitle of Object.keys(dataTitleIndexes)) {
        const labels = [];
        const values = {};
        const datasets = [];
        const averages = {};
        let idx = 0;
        for (let [key] of dataTitleIndexes[chartTitle]) {
          const dataset = {
            label: this.t(key),
            lineTension: 0,
            fill: false,
            backgroundColor: getcolor(colorIdx, key),
            borderColor: getcolor(colorIdx, key),
            type: "line",
            pointRadius: 1,
            borderWidth: 2,
            data: [],
          };
          if (dataType === 'ALL' && key === 'Amazon価格') {
            dataset.backgroundColor = `${getcolor(colorIdx, key)}11`;
            dataset.fill = true;
          }
          if (dataType === 'COLLECTOR' || dataType === 'RANKING') {
            dataset.yAxisID = 'yleft';
          }
          else {
            dataset.yAxisID = key === 'ランキング' ? 'yright' : 'yleft';
          }
          datasets.push(dataset);
          for (let date of Object.keys(data).sort()) {
            if (minDate.diff(moment(date)) >= 0) continue;
            if (idx === 0) labels.push(date);
            if (!values[key]) values[key] = [];
            if (this.item.domainId == this.$store.getters.getComDomain && chartTitle === '最安値' && key !== 'ランキング') {
              // COM版は価格をドル変換
              values[key].push(data[date][key] / 100);
              dataset.data.push(data[date][key] / 100);
            }
            else {
              values[key].push(data[date][key]);
              dataset.data.push(data[date][key]);
            }
          }
          if (values[key] != void 0) {
            let sum = values[key].filter(e => e > 0).reduce((p, c) => p + c, 0);
            let dataCountForAverage = values[key].filter(e => e > 0).length;
            averages[key] = {value: dataCountForAverage > 0 ? Math.round(sum / dataCountForAverage) : ' - ', color: getcolor(colorIdx, key)};
            if (key === 'ランキング') {
              const catid = this.salesRankCat != void 0 ? this.salesRankCat.catId : this.item.salesRankReference;
              const r = this.estimateSales(catid, this.item.domainId, averages['ランキング'].value);
              this.salesEstimations.weekly = r == void 0 ? '-' : Math.floor(r / 4);
              this.salesEstimations.monthly = r ?? '-';
            }
          }
          idx++;
          colorIdx++;
          if (colorIdx === RANKING_COLORINDEX) {
            colorIdx++;
          }
        }
        chartData[chartTitle] = {
          labels,
          datasets,
        };
        this.chartAverages[chartTitle] = averages;
      }
      this.chartData = chartData;
    },
    price(val) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    flagClicked(domainId, asin) {
      this.$router.push({ name: 'Detail', params: { asin }, query: { domain: domainId } });
    },
    analysisTitle() {
      const message = AIChat.createMessageFromTitle(this.item.title);
      this.$refs.aichat.show(message);
    },
    searchImage(item) {
      if (item.imagesCSV != void 0) {
        const route = this.$router.resolve({ name: 'Aibuy', query: { image: item.imagesCSV.split(',')[0] } });
        window.open(route.href, '_blank');
      }
    },
    aianalysis() {
      let historical = this.historical;
      if (this.historicalDataTerm !== HISTORICAL_DATA_TERMS.MON1) {
        // 6ヵ月以上は文字数が長くてエラーになるので最長3ヵ月分のデータで処理を行う。
        historical = this.getHistoricalData(this.csv, this.historicalDataType, HISTORICAL_DATA_TERMS.MON3);
      }
      const message = AIChat.createMessageFromHistoricalData(this.item, this.historicalDataType, historical);
      this.$refs.aichat.show(message);
    },
    productInfoAianalysis() {
      const message = AIChat.createMessageFromProductInfo(
        this.item,
        this.$refs.productInfo.getProductInfoText(),
        this.$refs.rankingDrop.getRankingDropText(),
        this.$refs.productInfo.getAvg90Text()
      );
      this.$refs.aichat.show(message);
    },
    rankingDropLoaded() {
      this.$refs.productInfo.disableAI = false;
      this.$refs.productSellerList.disabled = false;
    },
  },
};
</script>
<style scoped>
.cloak {
  display: none !important;
}
.chartStyle {
  width: 100%;
  height: 250px;
}

.historicalDataTable {
  overflow-x: hidden;
}
.historicalDataTable table {
  display: flex;
  flex-flow: column;
  width: 98%;
  height: 70vh;
}
.historicalDataTable thead {
  flex: 0 0 auto;
}
.historicalDataTable tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}
.historicalDataTable tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.header-top th {
  border-bottom: 0 solid transparent;
}
.header-bottom {
  height: 18px;
}
.header-bottom th {
  font-size: 10px;
  padding: 0;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
}

.tagpremier {
  background-color: #999237;
}
.tagsize {
  background-color: #3e9937;
}
.tagsal {
  background-color: #5eb957;
}
.taglower {
  background-color: #90af8f;
}
.tagparent {
  background-color: #fff3cd;
  color: #856404;
}

.chartAverage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #666;
  background-color: #e9ecef;
  font-size: 12px;
  font-weight: bold;
}

.keepa-graph {
  width: 70%;
}

.footer {
  height: 70px;
}
.grid-container {
  display: grid;
  row-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1280px));
}

.table-responsive {
  margin-bottom: 0 !important;
}

.grid-hr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
    padding-bottom: 1em;
}

@media (max-width: 575.98px) {
  .keepa-graph {
    width: 100%;
  }
  .historicalDataTable th, .historicalDataTable td {
    font-size: 10px;
  }
  .grid-container {
    grid-auto-columns: calc(100vw - 30px);
  }

}
</style>

