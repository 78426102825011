<template>
  <div class="page-content">
    <div class="container">
      <manual-link>{{ t('年間機能管理') }}</manual-link>

      <div>{{ t('年間機能を強制解放したいメールアドレスを入力してください。') }}</div>
      <b-input-group class="inputgroup">
        <b-form-input type="email" v-model='newemail' :placeholder="t('メールアドレス')"></b-form-input>
        <b-input-group-append>
          <b-button class="btn bg-eresa text-white px-3" type="button" @click="$refs.addConfirm.show()" :disabled="newemail.length === 0">{{ t('登録') }}</b-button>
        </b-input-group-append>
        <b-spinner v-if="adding" variant="primary" small class="ml-2"></b-spinner>
      </b-input-group>
      <b-alert :show="errorMassageRegister != ''" variant="danger">{{ errorMassageRegister }}</b-alert>

      <div class="mt-4">
        <label>{{ t('メールアドレス一覧：') }}
          <b-button class="btn bg-eresa text-white px-3" type="button" @click="$refs.removeConfirm.show()">{{ t('選択されたメールアドレスの年間機能を解除') }}</b-button>
        </label>
        <b-spinner v-if="loading" variant="primary" small class="ml-2"></b-spinner>
        <div v-if="emailList != void 0" class="container email-table mt-2">
          <div class="d-flex justify-content-between font-weight-bold border-bottom email-table-header">
            No
          </div>
          <div class="d-flex justify-content-between font-weight-bold border-bottom email-table-header">
            {{ t('選択') }}
          </div>
          <div class="d-flex justify-content-between font-weight-bold border-bottom email-table-header">
            {{ t('メールアドレス') }}
            <div>
              <b-icon icon="sort-up-alt" class="sort mr-2" @click="sort('email', false)"></b-icon>
              <b-icon icon="sort-down" class="sort mr-2" @click="sort('email', true)"></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-between font-weight-bold border-bottom email-table-header">
            {{ t('会員種別') }}
          </div>
          <template v-for="(item, i) in emailList">
            <div class="border-bottom email-table-col text-center" :key="`no-${i}`">{{ i + 1 }}</div>
            <div class="border-bottom email-table-col text-center" :key="`checked-${i}`"><b-form-checkbox v-model="item.checked"></b-form-checkbox></div>
            <div class="border-bottom email-table-col" :key="`email-${i}`">{{ item.email }}</div>
            <div class="border-bottom email-table-col" :key="`subscriberType-${i}`">
              <template v-if="item.subscriberType == void 0">
                <b-button class="btn bg-eresa text-white px-3 py-0" type="button" @click="showSubscriberType(i)">{{ t('会員種別を表示') }}</b-button>
                <b-spinner v-if="item.loading" variant="primary" small class="ml-2"></b-spinner>
              </template>
              <template v-else>
                {{ item.subscriberType }}
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>

    <b-modal ref="addConfirm" @ok="addEmail" :title="t('登録')" :ok-title="t('登録')" :cancel-title="t('キャンセル')">
      {{ t('登録します。よろしいですか？') }}
    </b-modal>
    <b-modal ref="removeConfirm" @ok="removeEmails" :title="t('解除')" :ok-title="t('解除')" :cancel-title="t('キャンセル')">
      {{ t('解除します。よろしいですか？') }}
    </b-modal>
  </div>
</template>

<script>
import ManualLink from '@/components/ManualLink.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { EresaApis } from '@/mixins/EresaApis';

export default {
  name: 'YearProMails',
  components: {
    ManualLink,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      newemail: '',
      adding: false,
      loading: false,
      loadingSubscriberType: false,
      emailList: [],
      errorMassageRegister: '',
    }
  },
  async mounted() {
    await this.showEmailList();
    await this.validateAdmin();
  },
  methods: {
    async addEmail() {
      this.errorMassageRegister = '';
      if (this.emailList.find(m => m.email === this.newemail) != void 0) {
        this.errorMassageRegister = this.t('メールアドレスは登録済みです。');
        return;
      }
      this.adding = true;
      try {
        try {
          await this.getSubscriberType(true, this.newemail);
        }
        catch {
          this.errorMassageRegister = this.t('ERESA未登録のメールアドレスです。');
          return;
        }
        const r = await EresaApis.addEresaProYearMails([this.newemail]);
        this.emailList = this.parseEmailList(r);
        this.sort('email', false);
      }
      finally {
        this.adding = false;
      }
    },
    async removeEmails() {
      this.loading = true;
      try {
        const r = await EresaApis.removeEresaProYearMails(this.emailList.filter(m => m.checked).map(m => m.email));
        this.emailList = this.parseEmailList(r);
        this.sort('email', false);
      }
      finally {
        this.loading = false;
      }
    },
    async showEmailList() {
      this.loading = true;
      try {
        const r = await EresaApis.getEresaProYearMails();
        this.emailList = this.parseEmailList(r);
        this.sort('email', false);
      }
      finally {
        this.loading = false;
      }
    },
    sort(colname, desc) {
      if (this.emailList.find(m => m.loading) != void 0) {
        return;
      }
      if (desc == void 0) {
        return;
      }
      this.emailList.sort((a, b) => {
        if (a[colname] === b[colname]) {
          return 0;
        }
        else {
          if (desc) {
            return a[colname] < b[colname] ? 1 : -1;
          }
          else {
            return a[colname] < b[colname] ? -1 : 1;
          }
        }
      });
    },
    parseEmailList(list) {
      return list.map(m => ({ email: m, checked: false, subscriberType: null, loading: false }));
    },
    async showSubscriberType(i) {
      this.emailList[i].loading = true;
      try {
        const email = this.emailList[i].email;
        const r = await this.getSubscriberType(true, email);
        if (r.name === 'EresaAdmin') {
          this.emailList[i].subscriberType = this.t('管理用ユーザー');
        }
        else if (r.isPermanent) {
          this.emailList[i].subscriberType = this.t('ERESA PRO会員（永久会員）');
        }
        else if (r.name === 'EresaSubscriber') {
          this.emailList[i].subscriberType = this.t('ERESA PRO会員（有料会員）');
        }
        else if (r.name === 'EresaSubscriber') {
          this.emailList[i].subscriberType = this.t('ERESA PRO会員（アプリ版から課金された有料会員）');
        }
        else {
          this.emailList[i].subscriberType = this.t('無料会員');
        }
      }
      catch {
        this.emailList[i].subscriberType = this.t('不明なユーザー（退会している可能性があります）');
      }
      finally {
        this.emailList[i].loading = false;
      }
    },
  },
}
</script>

<style scoped>
.inputgroup {
  max-width: 480px;
}

.email-table {
  display:grid;
  grid-template-columns: 2em 5em 20em 40em;
}

.email-table-header {
  background-color: #ccc;
}

.email-table-col {
  background-color: #fff;
}

.sort {
  cursor: pointer;
}
</style>
