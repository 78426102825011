export default {
  "AiBUY（1688向け商品リサーチおよび注文サービス）のご案内": "AiBUY (product research and ordering service for 1688)",
  "AiBUY（1688向け商品リサーチおよび注文サービス）は、1688が商品データを提供し、RAKUMART株式会社が運営するサービスです。": "AiBUY (product research and ordering service for 1688) is a service provided by 1688 with product data and operated by RAKUMART Co.",
  "ご利用にあたっては、利用規約およびプライバシーポリシーをご確認いただき、同意の上ご利用ください。": "Please read and agree to the Terms of Use and Privacy Policy before using the site.",
  "利用規約およびプライバシーポリシーに同意してAiBUYを有効化する": "I agree to the Terms of Use and Privacy Policy and activate AiBUY",
  "利用規約": "Terms of Use",
  "および": "and",
  "プライバシーポリシー": "Privacy policy",
  "checklabelen": "I agree to the",
  "checklabelja": " ",
  "こちらの機能は現在、パソコンでのみご利用いただけます。": "This feature is currently only available on PCs.",
}
