/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const removeUser = /* GraphQL */ `
  mutation RemoveUser($mailaddress: String, $bymailaddress: Boolean) {
    removeUser(mailaddress: $mailaddress, bymailaddress: $bymailaddress)
  }
`;
export const updateUserAttributes = /* GraphQL */ `
  mutation UpdateUserAttributes($organization: String, $prevEmail: String) {
    updateUserAttributes(organization: $organization, prevEmail: $prevEmail)
  }
`;
export const addAppStoreSubscription = /* GraphQL */ `
  mutation AddAppStoreSubscription(
    $originalTransactionId: String!
    $prepare: Boolean
  ) {
    addAppStoreSubscription(
      originalTransactionId: $originalTransactionId
      prepare: $prepare
    )
  }
`;
export const makeGraphs = /* GraphQL */ `
  mutation MakeGraphs($asin: String!, $domain: String) {
    makeGraphs(asin: $asin, domain: $domain)
  }
`;
export const updateUserCount = /* GraphQL */ `
  mutation UpdateUserCount($key: String!, $count: Int!, $initialCount: Int) {
    updateUserCount(key: $key, count: $count, initialCount: $initialCount)
  }
`;
export const affiliateOperation = /* GraphQL */ `
  mutation AffiliateOperation($operation: String!, $args: AWSJSON) {
    affiliateOperation(operation: $operation, args: $args)
  }
`;
export const adminOperation = /* GraphQL */ `
  mutation AdminOperation($operation: String!, $args: AWSJSON) {
    adminOperation(operation: $operation, args: $args)
  }
`;
export const aibuyOperation = /* GraphQL */ `
  mutation AibuyOperation($operation: String!, $args: AWSJSON) {
    aibuyOperation(operation: $operation, args: $args)
  }
`;
export const keepaOperation = /* GraphQL */ `
  mutation KeepaOperation($operation: String!, $args: AWSJSON) {
    keepaOperation(operation: $operation, args: $args)
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductLiteCache = /* GraphQL */ `
  mutation CreateProductLiteCache(
    $input: CreateProductLiteCacheInput!
    $condition: ModelProductLiteCacheConditionInput
  ) {
    createProductLiteCache(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductLiteCache = /* GraphQL */ `
  mutation UpdateProductLiteCache(
    $input: UpdateProductLiteCacheInput!
    $condition: ModelProductLiteCacheConditionInput
  ) {
    updateProductLiteCache(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductLiteCache = /* GraphQL */ `
  mutation DeleteProductLiteCache(
    $input: DeleteProductLiteCacheInput!
    $condition: ModelProductLiteCacheConditionInput
  ) {
    deleteProductLiteCache(input: $input, condition: $condition) {
      asin
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategoryCache = /* GraphQL */ `
  mutation CreateCategoryCache(
    $input: CreateCategoryCacheInput!
    $condition: ModelCategoryCacheConditionInput
  ) {
    createCategoryCache(input: $input, condition: $condition) {
      categoryId
      domain
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoryCache = /* GraphQL */ `
  mutation UpdateCategoryCache(
    $input: UpdateCategoryCacheInput!
    $condition: ModelCategoryCacheConditionInput
  ) {
    updateCategoryCache(input: $input, condition: $condition) {
      categoryId
      domain
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoryCache = /* GraphQL */ `
  mutation DeleteCategoryCache(
    $input: DeleteCategoryCacheInput!
    $condition: ModelCategoryCacheConditionInput
  ) {
    deleteCategoryCache(input: $input, condition: $condition) {
      categoryId
      domain
      json
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategoryRankingRange = /* GraphQL */ `
  mutation CreateCategoryRankingRange(
    $input: CreateCategoryRankingRangeInput!
    $condition: ModelCategoryRankingRangeConditionInput
  ) {
    createCategoryRankingRange(input: $input, condition: $condition) {
      categoryId
      range
      domain
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoryRankingRange = /* GraphQL */ `
  mutation UpdateCategoryRankingRange(
    $input: UpdateCategoryRankingRangeInput!
    $condition: ModelCategoryRankingRangeConditionInput
  ) {
    updateCategoryRankingRange(input: $input, condition: $condition) {
      categoryId
      range
      domain
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoryRankingRange = /* GraphQL */ `
  mutation DeleteCategoryRankingRange(
    $input: DeleteCategoryRankingRangeInput!
    $condition: ModelCategoryRankingRangeConditionInput
  ) {
    deleteCategoryRankingRange(input: $input, condition: $condition) {
      categoryId
      range
      domain
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategoryRankingArchive = /* GraphQL */ `
  mutation CreateCategoryRankingArchive(
    $input: CreateCategoryRankingArchiveInput!
    $condition: ModelCategoryRankingArchiveConditionInput
  ) {
    createCategoryRankingArchive(input: $input, condition: $condition) {
      id
      categoryId
      domain
      month
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoryRankingArchive = /* GraphQL */ `
  mutation UpdateCategoryRankingArchive(
    $input: UpdateCategoryRankingArchiveInput!
    $condition: ModelCategoryRankingArchiveConditionInput
  ) {
    updateCategoryRankingArchive(input: $input, condition: $condition) {
      id
      categoryId
      domain
      month
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoryRankingArchive = /* GraphQL */ `
  mutation DeleteCategoryRankingArchive(
    $input: DeleteCategoryRankingArchiveInput!
    $condition: ModelCategoryRankingArchiveConditionInput
  ) {
    deleteCategoryRankingArchive(input: $input, condition: $condition) {
      id
      categoryId
      domain
      month
      asins
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategoryRankingRegisteredMonths = /* GraphQL */ `
  mutation CreateCategoryRankingRegisteredMonths(
    $input: CreateCategoryRankingRegisteredMonthsInput!
    $condition: ModelCategoryRankingRegisteredMonthsConditionInput
  ) {
    createCategoryRankingRegisteredMonths(
      input: $input
      condition: $condition
    ) {
      categoryId
      registeredMonths {
        archiveId
        month
        domain
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategoryRankingRegisteredMonths = /* GraphQL */ `
  mutation UpdateCategoryRankingRegisteredMonths(
    $input: UpdateCategoryRankingRegisteredMonthsInput!
    $condition: ModelCategoryRankingRegisteredMonthsConditionInput
  ) {
    updateCategoryRankingRegisteredMonths(
      input: $input
      condition: $condition
    ) {
      categoryId
      registeredMonths {
        archiveId
        month
        domain
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategoryRankingRegisteredMonths = /* GraphQL */ `
  mutation DeleteCategoryRankingRegisteredMonths(
    $input: DeleteCategoryRankingRegisteredMonthsInput!
    $condition: ModelCategoryRankingRegisteredMonthsConditionInput
  ) {
    deleteCategoryRankingRegisteredMonths(
      input: $input
      condition: $condition
    ) {
      categoryId
      registeredMonths {
        archiveId
        month
        domain
        createdAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWarningBrands = /* GraphQL */ `
  mutation CreateWarningBrands(
    $input: CreateWarningBrandsInput!
    $condition: ModelWarningBrandsConditionInput
  ) {
    createWarningBrands(input: $input, condition: $condition) {
      id
      brands
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWarningBrands = /* GraphQL */ `
  mutation UpdateWarningBrands(
    $input: UpdateWarningBrandsInput!
    $condition: ModelWarningBrandsConditionInput
  ) {
    updateWarningBrands(input: $input, condition: $condition) {
      id
      brands
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWarningBrands = /* GraphQL */ `
  mutation DeleteWarningBrands(
    $input: DeleteWarningBrandsInput!
    $condition: ModelWarningBrandsConditionInput
  ) {
    deleteWarningBrands(input: $input, condition: $condition) {
      id
      brands
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWarningItem = /* GraphQL */ `
  mutation CreateWarningItem(
    $input: CreateWarningItemInput!
    $condition: ModelWarningItemConditionInput
  ) {
    createWarningItem(input: $input, condition: $condition) {
      key
      domain
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWarningItem = /* GraphQL */ `
  mutation UpdateWarningItem(
    $input: UpdateWarningItemInput!
    $condition: ModelWarningItemConditionInput
  ) {
    updateWarningItem(input: $input, condition: $condition) {
      key
      domain
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWarningItem = /* GraphQL */ `
  mutation DeleteWarningItem(
    $input: DeleteWarningItemInput!
    $condition: ModelWarningItemConditionInput
  ) {
    deleteWarningItem(input: $input, condition: $condition) {
      key
      domain
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationSettings = /* GraphQL */ `
  mutation CreateApplicationSettings(
    $input: CreateApplicationSettingsInput!
    $condition: ModelApplicationSettingsConditionInput
  ) {
    createApplicationSettings(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationSettings = /* GraphQL */ `
  mutation UpdateApplicationSettings(
    $input: UpdateApplicationSettingsInput!
    $condition: ModelApplicationSettingsConditionInput
  ) {
    updateApplicationSettings(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationSettings = /* GraphQL */ `
  mutation DeleteApplicationSettings(
    $input: DeleteApplicationSettingsInput!
    $condition: ModelApplicationSettingsConditionInput
  ) {
    deleteApplicationSettings(input: $input, condition: $condition) {
      key
      value
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavorite = /* GraphQL */ `
  mutation CreateFavorite(
    $input: CreateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    createFavorite(input: $input, condition: $condition) {
      owner
      asins {
        asin
        domain
        title
        memo
        date
        __typename
      }
      historyAsins {
        asin
        domain
        date
        __typename
      }
      browsingAsins {
        asin
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFavorite = /* GraphQL */ `
  mutation UpdateFavorite(
    $input: UpdateFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    updateFavorite(input: $input, condition: $condition) {
      owner
      asins {
        asin
        domain
        title
        memo
        date
        __typename
      }
      historyAsins {
        asin
        domain
        date
        __typename
      }
      browsingAsins {
        asin
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFavorite = /* GraphQL */ `
  mutation DeleteFavorite(
    $input: DeleteFavoriteInput!
    $condition: ModelFavoriteConditionInput
  ) {
    deleteFavorite(input: $input, condition: $condition) {
      owner
      asins {
        asin
        domain
        title
        memo
        date
        __typename
      }
      historyAsins {
        asin
        domain
        date
        __typename
      }
      browsingAsins {
        asin
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavoriteSeller = /* GraphQL */ `
  mutation CreateFavoriteSeller(
    $input: CreateFavoriteSellerInput!
    $condition: ModelFavoriteSellerConditionInput
  ) {
    createFavoriteSeller(input: $input, condition: $condition) {
      owner
      sellers {
        id
        domain
        displayName
        memo
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFavoriteSeller = /* GraphQL */ `
  mutation UpdateFavoriteSeller(
    $input: UpdateFavoriteSellerInput!
    $condition: ModelFavoriteSellerConditionInput
  ) {
    updateFavoriteSeller(input: $input, condition: $condition) {
      owner
      sellers {
        id
        domain
        displayName
        memo
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFavoriteSeller = /* GraphQL */ `
  mutation DeleteFavoriteSeller(
    $input: DeleteFavoriteSellerInput!
    $condition: ModelFavoriteSellerConditionInput
  ) {
    deleteFavoriteSeller(input: $input, condition: $condition) {
      owner
      sellers {
        id
        domain
        displayName
        memo
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      owner
      registered
      name
      organization
      membershipType
      stripeCustomer
      stripeDomain
      appStoreTransactionId
      aibuyUserId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      owner
      registered
      name
      organization
      membershipType
      stripeCustomer
      stripeDomain
      appStoreTransactionId
      aibuyUserId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      owner
      registered
      name
      organization
      membershipType
      stripeCustomer
      stripeDomain
      appStoreTransactionId
      aibuyUserId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStripeInfo = /* GraphQL */ `
  mutation CreateStripeInfo(
    $input: CreateStripeInfoInput!
    $condition: ModelStripeInfoConditionInput
  ) {
    createStripeInfo(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStripeInfo = /* GraphQL */ `
  mutation UpdateStripeInfo(
    $input: UpdateStripeInfoInput!
    $condition: ModelStripeInfoConditionInput
  ) {
    updateStripeInfo(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStripeInfo = /* GraphQL */ `
  mutation DeleteStripeInfo(
    $input: DeleteStripeInfoInput!
    $condition: ModelStripeInfoConditionInput
  ) {
    deleteStripeInfo(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStripeInfoCom = /* GraphQL */ `
  mutation CreateStripeInfoCom(
    $input: CreateStripeInfoComInput!
    $condition: ModelStripeInfoComConditionInput
  ) {
    createStripeInfoCom(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStripeInfoCom = /* GraphQL */ `
  mutation UpdateStripeInfoCom(
    $input: UpdateStripeInfoComInput!
    $condition: ModelStripeInfoComConditionInput
  ) {
    updateStripeInfoCom(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStripeInfoCom = /* GraphQL */ `
  mutation DeleteStripeInfoCom(
    $input: DeleteStripeInfoComInput!
    $condition: ModelStripeInfoComConditionInput
  ) {
    deleteStripeInfoCom(input: $input, condition: $condition) {
      customer
      username
      subscription
      periodEnd
      planId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppStoreInfo = /* GraphQL */ `
  mutation CreateAppStoreInfo(
    $input: CreateAppStoreInfoInput!
    $condition: ModelAppStoreInfoConditionInput
  ) {
    createAppStoreInfo(input: $input, condition: $condition) {
      originalTransactionId
      username
      active
      notificationUUID
      notificationType
      subtype
      expiresDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppStoreInfo = /* GraphQL */ `
  mutation UpdateAppStoreInfo(
    $input: UpdateAppStoreInfoInput!
    $condition: ModelAppStoreInfoConditionInput
  ) {
    updateAppStoreInfo(input: $input, condition: $condition) {
      originalTransactionId
      username
      active
      notificationUUID
      notificationType
      subtype
      expiresDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppStoreInfo = /* GraphQL */ `
  mutation DeleteAppStoreInfo(
    $input: DeleteAppStoreInfoInput!
    $condition: ModelAppStoreInfoConditionInput
  ) {
    deleteAppStoreInfo(input: $input, condition: $condition) {
      originalTransactionId
      username
      active
      notificationUUID
      notificationType
      subtype
      expiresDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppStoreTransactionLog = /* GraphQL */ `
  mutation CreateAppStoreTransactionLog(
    $input: CreateAppStoreTransactionLogInput!
    $condition: ModelAppStoreTransactionLogConditionInput
  ) {
    createAppStoreTransactionLog(input: $input, condition: $condition) {
      originalTransactionId
      notificationUUID
      notificationType
      subtype
      expiresDate
      transactionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppStoreTransactionLog = /* GraphQL */ `
  mutation UpdateAppStoreTransactionLog(
    $input: UpdateAppStoreTransactionLogInput!
    $condition: ModelAppStoreTransactionLogConditionInput
  ) {
    updateAppStoreTransactionLog(input: $input, condition: $condition) {
      originalTransactionId
      notificationUUID
      notificationType
      subtype
      expiresDate
      transactionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAppStoreTransactionLog = /* GraphQL */ `
  mutation DeleteAppStoreTransactionLog(
    $input: DeleteAppStoreTransactionLogInput!
    $condition: ModelAppStoreTransactionLogConditionInput
  ) {
    deleteAppStoreTransactionLog(input: $input, condition: $condition) {
      originalTransactionId
      notificationUUID
      notificationType
      subtype
      expiresDate
      transactionData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserSettings = /* GraphQL */ `
  mutation CreateUserSettings(
    $input: CreateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    createUserSettings(input: $input, condition: $condition) {
      owner
      settings {
        key
        value
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserSettings = /* GraphQL */ `
  mutation UpdateUserSettings(
    $input: UpdateUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    updateUserSettings(input: $input, condition: $condition) {
      owner
      settings {
        key
        value
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserSettings = /* GraphQL */ `
  mutation DeleteUserSettings(
    $input: DeleteUserSettingsInput!
    $condition: ModelUserSettingsConditionInput
  ) {
    deleteUserSettings(input: $input, condition: $condition) {
      owner
      settings {
        key
        value
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSearchConditions = /* GraphQL */ `
  mutation CreateSearchConditions(
    $input: CreateSearchConditionsInput!
    $condition: ModelSearchConditionsConditionInput
  ) {
    createSearchConditions(input: $input, condition: $condition) {
      owner
      conditions {
        id
        name
        condition
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSearchConditions = /* GraphQL */ `
  mutation UpdateSearchConditions(
    $input: UpdateSearchConditionsInput!
    $condition: ModelSearchConditionsConditionInput
  ) {
    updateSearchConditions(input: $input, condition: $condition) {
      owner
      conditions {
        id
        name
        condition
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSearchConditions = /* GraphQL */ `
  mutation DeleteSearchConditions(
    $input: DeleteSearchConditionsInput!
    $condition: ModelSearchConditionsConditionInput
  ) {
    deleteSearchConditions(input: $input, condition: $condition) {
      owner
      conditions {
        id
        name
        condition
        domain
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductMeta = /* GraphQL */ `
  mutation CreateProductMeta(
    $input: CreateProductMetaInput!
    $condition: ModelProductMetaConditionInput
  ) {
    createProductMeta(input: $input, condition: $condition) {
      asin
      domain
      pageview {
        count
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductMeta = /* GraphQL */ `
  mutation UpdateProductMeta(
    $input: UpdateProductMetaInput!
    $condition: ModelProductMetaConditionInput
  ) {
    updateProductMeta(input: $input, condition: $condition) {
      asin
      domain
      pageview {
        count
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductMeta = /* GraphQL */ `
  mutation DeleteProductMeta(
    $input: DeleteProductMetaInput!
    $condition: ModelProductMetaConditionInput
  ) {
    deleteProductMeta(input: $input, condition: $condition) {
      asin
      domain
      pageview {
        count
        date
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSharedSearchCondition = /* GraphQL */ `
  mutation CreateSharedSearchCondition(
    $input: CreateSharedSearchConditionInput!
    $condition: ModelSharedSearchConditionConditionInput
  ) {
    createSharedSearchCondition(input: $input, condition: $condition) {
      id
      username
      conditionId
      name
      condition
      domain
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSharedSearchCondition = /* GraphQL */ `
  mutation UpdateSharedSearchCondition(
    $input: UpdateSharedSearchConditionInput!
    $condition: ModelSharedSearchConditionConditionInput
  ) {
    updateSharedSearchCondition(input: $input, condition: $condition) {
      id
      username
      conditionId
      name
      condition
      domain
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSharedSearchCondition = /* GraphQL */ `
  mutation DeleteSharedSearchCondition(
    $input: DeleteSharedSearchConditionInput!
    $condition: ModelSharedSearchConditionConditionInput
  ) {
    deleteSharedSearchCondition(input: $input, condition: $condition) {
      id
      username
      conditionId
      name
      condition
      domain
      date
      createdAt
      updatedAt
      __typename
    }
  }
`;
