<template>
  <div class="page-content">
    <div class="container">
      <manual-link :href="manuallink">{{ t('トラッキング一覧') }}</manual-link>
    </div>
    <b-alert :show="!loading && asins.length <= 0" variant="info">{{ t('トラッキング対象の商品はありません。トラッキング対象商品の追加は商品詳細画面より行ってください。') }}</b-alert>

    <Loading v-if="loading"></Loading>

    <div v-if="asins && asins.length > 0" class="container">
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [count, start + 1, end]) }}</h6>
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="count" :per-page="countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="count" :per-page="countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, lastpage]) }}</div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="card shadow border-0 pb-4">
        <div class="card-body">
          <tracking-list :asins="asins" :key="trackingListKey" @deleteItems="deleteItems"></tracking-list>
        </div>
      </div>
    </div>

    <div v-if="asins && asins.length > 0" class="container">
      <div class="pagepanel d-flex d-sm-none">
        <b-pagination v-model="page" :total-rows="count" :per-page="countPerPage" class="pagination-eresa" size="sm"></b-pagination>
        <div class="small ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, lastpage]) }}</div>
      </div>
      <div class="pagepanel d-none d-sm-flex">
        <b-pagination v-model="page" :total-rows="count" :per-page="countPerPage" class="pagination-eresa"></b-pagination>
        <div class="ml-2 mt-2">{{ t('{0} ／ {1} ページ', [page, lastpage]) }}</div>
      </div>
      <h6>{{ t('{0}件中 {1} ～ {2}件を表示', [count, start + 1, end]) }}</h6>
    </div>

    <b-modal id="errorModal" ok-only title="ERROR">{{ t('トラッキング削除に失敗しました。') }}</b-modal>
  </div>
</template>

<script>
import ManualLink from '@/components/ManualLink.vue';
import TrackingList from '@/components/TrackingList.vue';
import Loading from "@/components/Loading.vue";
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
import { EresaApis } from '@/mixins/EresaApis';

const MANUAL_JP = 'https://pro.eresa.jp/function/tracking/about-tracking/';
const MANUAL_COM = 'https://eresa.io/function/tracking/about-tracking/';

export default {
  name: 'Tracking',
  components: {
    ManualLink,
    TrackingList,
    Loading,
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      countPerPage: 100,
      page: 1,
      count: 0,
      lastpage: 1,
      start: 0,
      end: 0,
      asins: [],
      trackingListKey: 0,
      loading: false,
      manuallink: this.isComEresa() ? MANUAL_COM : MANUAL_JP,
    }
  },
  async mounted() {
    await this.setList()
    await this.validateSubscriber();
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  watch: {
    domain() {
      this.gotoPage(1);
    },
    page(value) {
      this.gotoPage(value);
    },
  },
  methods: {
    async setList() {
      this.count = await this.gotoPage(1);
      this.lastpage = parseInt(Math.ceil(this.count / this.countPerPage));
      this.updatePage();
    },
    async gotoPage(page) {
      this.loading = true;
      const res = await EresaApis.getTrackingList(this.domain, this.countPerPage, page);
      if (!res) {
        this.$store.commit("setIsSubscriber", false);
        this.refreshToken();
        this.$router.push({ name: "Default" });
        return;
      }
      this.asins = res.asinList.map(item => item.asin);
      this.updatePage();
      this.trackingListKey++;
      this.loading = false;
      return res.count;
    },
    updatePage() {
      this.start = (this.page - 1) * this.countPerPage;
      if (this.page == this.lastpage) {
        this.end = this.count;
      }
      else {
        this.end = this.start + this.countPerPage;
      }
    },
    async deleteItems(asins) {
      if (asins.length <= 0) {
        return;
      }
      this.loading = true;
      try {
        for (const asin of asins) {
          await EresaApis.removeTracking(asin, this.domain);
        }
      }
      catch {
        this.$bvModal.show("errorModal");
      }
      finally {
        this.gotoPage(1);
      }
    },
  }
}
</script>
<style scoped>
</style>