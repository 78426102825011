<template>
  <div>
    <!-- Loop this for each content -->
    <div class="d-flex" :key="'top'">
      <b-form-checkbox v-if="list.length > 0" v-model="selectAll"><h6 class="mt-1">{{ t('すべて選択') }}</h6></b-form-checkbox>
      <b-button v-if="list.length > 0" class="bg-eresa text-white ml-auto d-sm-none" size="sm">{{ t('一括削除') }}</b-button>
      <b-button v-if="list.length > 0" class="bg-eresa text-white ml-auto d-none d-sm-block" @click="deleteItems">{{ t('一括削除') }}</b-button>
    </div>
    <template v-for="(item, i) in list">
      <div class="mt-2 pt-2 border-top d-flex flex-wrap" :key="'heading-' + i">
        <b-form-checkbox v-model="item.selected"></b-form-checkbox>
        <h6><b-badge v-if="item.tracking && item.tracking.metaData" class="tag">{{ item.tracking.metaData.tag }}</b-badge></h6>
        <div class="ml-auto d-flex">
          <h6><b-button variant="white" class="text-eresa delete" size="sm" @click="$emit('deleteItems', [item.asin])">{{ t('削除') }}</b-button></h6>
          <h6><b-button variant="white" class="text-eresa edit ml-2" size="sm" @click="editItem(item, domain)">{{ t('編集') }}</b-button></h6>
        </div>
      </div>
      <div :class="item.tracking == void 0 || (item.tracking.isActive && item.tracking.notificationCSV == void 0) ? 'd-flex' : 'bg-notified d-flex'" :key="'content-' + i">
        <div class="bg-transparent px-4 mt-2">
          <product-image :item="item" sm></product-image>
        </div>
        <div>
          <router-link :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain } }" class="text-eresa d-block mb-3" target="_blank">
            <h6 class="h6">{{ item.title }}</h6>
          </router-link>
          <div class="text-sm mb-2">
            <div>
              <h6 class="h6 text-secondary mb-1 d-flex flex-wrap">
                <span>{{ t('トラッキング開始日') }}:</span>
                <span>{{ item.tracking ? getDateFromKeepaTime(item.tracking.createDate) : '' | datetimeFormatter }}</span>
              </h6>
            </div>
            <div>
              <h6 class="h6 text-secondary mb-1">
                {{ t('トラッキング期間') }}: {{ item.tracking ? `${parseInt(item.tracking.ttl / 24)}${t('日')}` : '' }}
              </h6>
            </div>
            <div>
              <h6 class="h6 text-secondary mb-1">
                {{ t('メモ') }}: {{ item.tracking && item.tracking.metaData ? item.tracking.metaData.memo : '' }}
              </h6>
            </div>
            <div class="d-flex flex-wrap">
              <h6 class="h6 text-uppercase text-secondary mb-1 font-weight-bold mr-3">Asin: {{ item.asin }}</h6><h6 class="h6 text-uppercase text-secondary mb-1 font-weight-bold">{{ t('JAN') }}: {{ item.eanList ? item.eanList[0] : '-' }}</h6>
            </div>
            <div>
              <h6 class="h6 text-secondary mb-1">
                {{ t('メーカー') }}: {{ item.brand }}
              </h6>
            </div>
            <div>
              <h6 class="h6 text-notified mb-1">
                {{ item.tracking && (!item.tracking.isActive || item.tracking.notificationCSV != void 0) ? getCause(item) : '' }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { API, graphqlOperation, I18n } from "aws-amplify";
import * as queries from "@/graphql/queries";
import Utils from "@/mixins/utils";
import moment from "moment";
import ProductImage from "@/components/Product/ProductImage";
import { EresaApis } from '@/mixins/EresaApis';

function datetimeFormatter(date) {
  return date ? moment(date).format(I18n.get('YYYY年MM月DD日 HH時mm分')) : '';
}

export default {
  name: 'TrackingList',
  props: ['asins'],
  data: function () {
    return {
      list: [],
      selectAll: false,
      domain: this.$store.getters.isDomainCom ? this.$store.getters.getDomain : void 0,
    };
  },
  components: {
    ProductImage,
  },
  mixins: [Utils],
  filters: {
    datetimeFormatter(date) {
      return datetimeFormatter(date);
    },
  },
  watch: {
    selectAll(value) {
      this.selectItems(value);
    },
  },
  async created() {
    if (!this.asins) return;
    this.list = [];
    this.asins.forEach(async (asin) => {
      const item = { asin, tracking: null, selected: false };
      this.list.push(item);
    });
    this.list.forEach(async (item) => {
      this.getProduct(item.asin).then(res =>
        Object.keys(res).forEach((key) => this.$set(item, key, res[key]))
      );
      const res = await EresaApis.getTracking(item.asin, this.$store.getters.getDomain);
      item.tracking = res.trackings[0];
      if (item.tracking.metaData != void 0) {
        item.tracking.metaData = JSON.parse(item.tracking.metaData);
        item.tracking.metaData.memo = item.tracking.metaData.memo ? unescape(item.tracking.metaData.memo) : '';
        item.tracking.metaData.tag = item.tracking.metaData.tag ? unescape(item.tracking.metaData.tag) : '';
      }
    });
  },
  methods: {
    datetimeFormat(date) {
      return datetimeFormatter(date);
    },
    async getProduct(asin) {
      return API.graphql(
        graphqlOperation(queries.getProductDetail, { asin, isDetail: false, domain: this.$store.getters.getDomain })
      ).then((rslt) => JSON.parse(rslt.data.getProductDetail.json)[0]);
    },
    getCause(item) {
      if (!item.tracking.isActive) {
        return this.t('追跡は無効です。');
      }
      if (item.tracking.notificationCSV == void 0) {
        return null;
      }
      const csvType = item.tracking.notificationCSV[1]; //Product.CsvType
      const target = [
        'Amazon', '新品', '中古', '売れ筋ランキング', '参考価格',
        'コレクター商品', '整備済み品', '新品（送料込）', 'タイムセール', '中古(FBA)',
        '新品(FBA)', '新品', '中古', '整備済み品', 'コレクター商品',
        '', '評価', 'レビュー数', 'カート', '中古（送料込）',
        '中古（送料込）', '中古（送料込）', '中古（送料込）',
      ].map(t => this.t(t))[csvType];
      const cause = item.tracking.notificationCSV[3]; //TrackingNotificationCause
      const text = [
        this.t('追跡の有効期限が切れました。'),
        this.t(`{0}の価格または値の条件が成立しました。`, [target]),
        this.t(`{0}の変更後の価格または値の条件が成立しました。`, [target]),
        this.t(`{0}の商品が在庫切れになりました。`, [target]),
        this.t(`{0}の商品が再入荷しました。`, [target]),
        this.t(`{0}の再調整後の価格または値の条件が成立しました。`, [target]),
        this.t('タイムセールが発表されました。'),
      ][cause];
      return `[${this.datetimeFormat(this.getDateFromKeepaTime(item.tracking.notificationCSV[4]))}] ${text}`;
    },
    selectItems(select) {
      if (select) {
        //すべて選択
        this.list.forEach(item => item.selected = true);
        return;
      }
      if (this.list.filter(item => !item.selected).length <= 0) {
        this.list.forEach(item => item.selected = false);
      }
    },
    editItem(item, domain) {
      this.$router.push({ name: "Tracking", params: { asin: item.asin }, query: { domain } });
    },
    deleteItems() {
      this.$emit('deleteItems', this.list.filter(item => item.selected).map(item => item.asin));
    },
  },
};
</script>

<style scoped>
.bg-notified {
  background-color: #f2f2f2;
  border-radius: 5px;
}
.text-notified {
  color: #99373e;
}
.badge.tag {
  background-color: #a9477f;
}
.edit, .delete {
  border-color: #ddd;
}
.edit:hover, .delete:hover {
  color: #376f99;
}
@media (max-width: 575.98px) {
  h6 {
    font-size: 10pt;
  }
}
</style>
