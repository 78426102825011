<template>
  <div>
    <span v-if="offerList && offerList.length"
      >{{
        getDateFromKeepaTime(offerList[0].lastSeen) | datetimeFormatter
      }} {{ t('lastupdated') }}</span
    >
    <div class="d-none d-sm-block">
      <b-card>
        <b-row>
          <b-col cols="2">{{ t('商品価格+配送料') }}</b-col>
          <b-col cols="4">{{ t('コンディション') }}</b-col>
          <b-col cols="3">{{ t('販売/出品') }}</b-col>
          <b-col cols="3">{{ t('出荷元') }}</b-col>
        </b-row>
      </b-card>
    </div>
    <b-card v-for="(row, i) in offerList" :key="i">
      <div class="d-none d-sm-block">
        <b-row>
          <b-col cols="2">
            <div class="font-weight-bold">{{ conditions[row.condition] }}</div>
            <div>{{ price(getCurrentPrice(row.offerCSV)) }}</div>
            <div v-if="getCurrentShipping(row.offerCSV) === 0">
              {{ t('通常配送無料') }}
            </div>
            <div v-else>
              + {{ price(getCurrentShipping(row.offerCSV)) }}{{ t('(配送料)') }}
            </div>
          </b-col>
          <b-col cols="4" v-if="!row.conditionComment">-</b-col>
          <b-col cols="4" v-else>
            <div v-if="showAllCommentFlagMap[row.offerId]">
              {{ row.conditionComment }}
            </div>
            <div v-else>
              {{ row.conditionComment.substr(0, 100)
              }}<b-button
                v-if="row.conditionComment.length >= 100"
                variant="link"
                @click="$set(showAllCommentFlagMap, row.offerId, true)"
                >{{ t('続きを読む') }}</b-button
              >
            </div>
          </b-col>
          <b-col cols="3" v-if="row.seller">
            <a
              :href="`https://www.amazon${domainName}/sp?_encoding=UTF8&asin=${asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${row.seller.sellerId}`"
              target="_blank"
              >{{ row.seller.sellerName }}</a
            >
            <div class="d-inline ml-2" >
              <img v-if="getSellerCountryCode(row.seller) === 'US'" class="flag" src="/img/flag_com.png" alt />
              <img v-else-if="getSellerCountryCode(row.seller) === 'JP'" class="flag border" src="/img/flag_jp.png" alt />
              <img v-else-if="getSellerCountryCode(row.seller) === 'CN'" class="flag" src="/img/flag_cn.png" alt />
              <div v-else-if="getSellerCountryCode(row.seller) != void 0" class="country-code">{{ getSellerCountryCode(row.seller) }}</div>
            </div>
            <div>
              {{ t('評価') }}: {{ getCurrentRating(row.seller.csv) }} ({{
                getCurrentRatingCount(row.seller.csv)
              }}{{ t('件') }})
            </div>
            <div class="d-flex">
              {{ t('{0}%の高評価', [row.seller.currentRating]) }}
              <b-button variant="white" class="border py-0 px-2 ml-2" :title="t('セラーリサーチ')" @click="sellerResearch(row.seller.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
            </div>
          </b-col>
          <b-col cols="3" v-if="row.seller">
            <div v-if="row.isFBA">Amazon</div>
            <div v-else>{{ row.seller.sellerName }}</div>
            <b-badge variant="danger" v-if="row.isPrime" class="mt-2 mr-2">Prime</b-badge>
            <b-badge variant="danger" v-if="row.shipsFromChina" class="mt-2 mr-2">{{ t('中国から発送') }}</b-badge>
            <div v-if="row.isShippable">{{ t('在庫あり') }}</div>
          </b-col>
        </b-row>
      </div>

      <div class="d-xs-block d-sm-none">
        <b-row class="border-bottom">
          <b-col cols="3" class="border-right">{{ t('商品価格+配送料') }}</b-col>
          <b-col cols="9">
            <div class="font-weight-bold">{{ conditions[row.condition] }}</div>
            <div>{{ price(getCurrentPrice(row.offerCSV)) }}</div>
            <div v-if="getCurrentShipping(row.offerCSV) === 0">
              {{ t('通常配送無料') }}
            </div>
            <div v-else>
              + {{ price(getCurrentShipping(row.offerCSV)) }}{{ t('(配送料)') }}
            </div>
          </b-col>
        </b-row>
        <b-row class="border-bottom">
          <b-col cols="3" class="border-right">{{ t('コンディション') }}</b-col>
          <b-col cols="9" v-if="!row.conditionComment">-</b-col>
          <b-col cols="9" v-else>
            <div v-if="showAllCommentFlagMap[row.offerId]">
              {{ row.conditionComment }}
            </div>
            <div v-else>
              {{ row.conditionComment.substr(0, 100)
              }}<b-button
                v-if="row.conditionComment.length >= 100"
                variant="link"
                @click="$set(showAllCommentFlagMap, row.offerId, true)"
                >{{ t('続きを読む') }}</b-button
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="border-bottom">
          <b-col cols="3" class="border-right">{{ t('販売/出品') }}</b-col>
          <b-col cols="9" v-if="row.seller">
            <a
              :href="`https://www.amazon${domainName}/sp?_encoding=UTF8&asin=${asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${row.seller.sellerId}`"
              target="_blank"
              >{{ row.seller.sellerName }}</a
            >
            <div class="d-inline ml-2" >
              <img v-if="getSellerCountryCode(row.seller) === 'US'" class="flag" src="/img/flag_com.png" alt />
              <img v-else-if="getSellerCountryCode(row.seller) === 'JP'" class="flag border" src="/img/flag_jp.png" alt />
              <img v-else-if="getSellerCountryCode(row.seller) === 'CN'" class="flag" src="/img/flag_cn.png" alt />
              <div v-else-if="getSellerCountryCode(row.seller) != void 0" class="country-code">{{ getSellerCountryCode(row.seller) }}</div>
            </div>
            <div>
              {{ t('評価') }}: {{ getCurrentRating(row.seller.csv) }} ({{
                getCurrentRatingCount(row.seller.csv)
              }}{{ t('件') }})
            </div>
            <div class="d-flex">
              {{ t('{0}%の高評価', [row.seller.currentRating]) }}
              <b-button variant="white" class="border py-0 px-2 ml-2 mb-1" :title="t('セラーリサーチ')" @click="sellerResearch(row.seller.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3" class="border-right">{{ t('出荷元') }}</b-col>
          <b-col cols="9" v-if="row.seller">
            <div v-if="row.isFBA">Amazon</div>
            <div v-else>{{ row.seller.sellerName }}</div>
            <b-badge variant="danger" v-if="row.isPrime" class="mt-2 mr-2">Prime</b-badge>
            <b-badge variant="danger" v-if="row.shipsFromChina" class="mt-2 mr-2">{{ t('中国から発送') }}</b-badge>
            <div v-if="row.isShippable">{{ t('在庫あり') }}</div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <infinite-loading
      v-if="offers.length > 10"
      @infinite="infiniteHandler"
      spinner="spiral"
    >
      <!--
      <div slot="spinner">Loading...</div>
      <div slot="no-more">{{ t('もう検索データが無いよ！') }}</div>
      <div slot="no-results">No more data</div>
      -->
    </infinite-loading>
  </div>
</template>
<script>
import Utils from "@/mixins/utils";
import { I18n } from "aws-amplify";
import moment from "moment";
import { EresaApis } from '@/mixins/EresaApis';

const COUNT_OF_PAGE = 10;
const getConditions = () => ({
  0: '-',
  1: I18n.get('新品'),
  2: I18n.get('中古商品 - ほぼ新品'),
  3: I18n.get('中古商品 - 非常に良い'),
  4: I18n.get('中古商品 - 良い'),
  5: I18n.get('中古商品 - 可'),
  6: I18n.get('再生品'),
  7: I18n.get('コレクター商品 - ほぼ新品'),
  8: I18n.get('コレクター商品 - 非常に良い'),
  9: I18n.get('コレクター商品 - 良い'),
  10:I18n.get('"コレクター商品 - 可'),
  11:I18n.get('"レンタル'),
});

export default {
  name: "OfferList",
  props: ["offers", "asin"],
  components: {},
  data() {
    return {
      offerList: [],
      page: 1,
      isCompleted: false,
      conditions: getConditions(),
      showAllCommentFlagMap: {},
      domain: this.$store.getters.getDomain,
      domainName: this.$store.getters.getDomainName,
    };
  },
  mixins: [Utils],
  filters: {
    datetimeFormatter(date) {
      return moment(date).format(I18n.get('YYYY年MM月DD日 HH時mm分'));
    },
  },
  created() {},
  async mounted() {
    //console.log(this.offers);
    if (this.offers) this.setOffers(this.offers, this.domain, this.page++);
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  watch: {},
  computed: {},
  methods: {
    async infiniteHandler($state) {
      if (this.isCompleted) {
        $state.complete();
        this.isCompleted = false;
      } else {
        this.setOffers(this.offers, this.domain, this.page++);
        $state.loaded();
        this.isCompleted = true;
      }
    },
    setOffers(offers, domain, page) {
      offers
        .slice((page - 1) * COUNT_OF_PAGE, page * COUNT_OF_PAGE)
        .forEach(async (offer) => {
          // console.log(offer);
          this.offerList.push(offer);
          const seller = await EresaApis.getSeller(offer.sellerId, false, domain);
          this.$set(offer, 'seller', seller);
        });
    },
    getCurrentPrice(csv) {
      if (!csv) return "-";
      return csv[csv.length - 2];
    },
    getCurrentShipping(csv) {
      if (!csv) return "-";
      return csv[csv.length - 1];
    },
    getCurrentRating(csv) {
      if (!csv || !csv[0]) return "-";
      return csv[0][csv[0].length - 1] / 20;
    },
    getCurrentRatingCount(csv) {
      if (!csv || !csv[1]) return "-";
      return csv[1][csv[1].length - 1];
    },
    getSellerCountryCode(seller) {
      const address = seller.address;
      const code = address == void 0 ? null : address[address.length - 1];
      return code;
    },
    price(val) {
      if (this.domain == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    sellerResearch(sellerid) {
      this.$router.push({ name: "SellerResearch", params: { sellerid }, query: { domain: this.domain } });
    },
  },
};
</script>
<style scoped>
.searchicon {
  width: 10px;
}

.country-code {
  display: inline;
  font-size: 0.75em;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 2px;
}

.flag {
  width: 18px;
  height: 12px;
  object-fit: cover;
}
</style>
