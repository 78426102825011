export default {
  "お知らせ設定": "Notification settings",
  "公開設定": "public settings",
  "公開": "Public",
  "非公開": "Private",
  "タイトル": "Title",
  "リンク先": "Link URL",
  "変更": "Change",
  "キャンセル": "Cancel",
  "変更します。よろしいですか？": "Do you want to update your status?",
}
