<template>
  <div>
    <!-- Loop this for each content -->
    <template v-for="(item, i) in list">
      <b-row :class="i > 0 ? 'mt-3 pt-3 border-top' : ''" :key="i">
        <!-- Hide this on mobile ( less than 576px ) -->
        <div class="d-none d-md-flex flex-wrap">
          <b-col cols="2" class="text-right">
            <div>
              <product-image :item="item" :favorite="nolink == void 0 || login != void 0 ? 'favorite' : undefined" sm class="text-center"></product-image>
              <div v-if="subscriber === true" class="ml-2 mt-4"><b-button size="sm" class="btn bg-eresa text-white imgsearch" @click="searchImage(item)">{{ t('1688で検索') }}</b-button></div>
              <lock-pro-button v-else-if="subscriber === false" text="1688で検索" class="ml-2 mt-4" sm></lock-pro-button>
            </div>
            <div class="w-100" style="padding-left: 250px"></div>
            <b-spinner v-if="loadingIndex === i" variant="primary" small class="mr-2"></b-spinner>
            <b-icon icon="graph-up" style="cursor: pointer" @click="graphClick(item, i)"></b-icon>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-between">
              <router-link v-if="nolink == void 0" :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: linkDomain(item.domainId) } }" class="text-eresa d-block mb-3" target="_blank">
                <h2 class="h6"><b-icon v-if="subscriber == true && item.isDanger" class="text-eresa text-danger mr-2" icon="exclamation-triangle"></b-icon>{{ item.title }}</h2>
              </router-link>
              <a v-else href="#" class="text-eresa d-block mb-3">
                <h2 class="h6"><b-icon v-if="subscriber == true && item.isDanger" class="text-eresa text-danger mr-2" icon="exclamation-triangle"></b-icon>{{ item.title }}</h2>
              </a>
              <div class="d-flex justify-content-end" style="min-width: 100px">
                <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white aianalysis" @click="analysisTitle(item.title)">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
                <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2" sm></lock-pro-button>
              </div>
            </div>
            <b-row class="text-sm">
              <b-col cols="12" lg="6" class="mb-2 mb-lg-0">
                <price-table-sm :item="item" :collapsekey="i" :subscriber="subscriber" :nolink="nolink"></price-table-sm>
              </b-col>
              <b-col>
                <span v-if="isPremier(item)" class="small p-1 text-white mr-1 tagpremier">{{ t('プレミア') }}</span>
                <span v-if="getSizeTypeString(item) != ''" class="small p-1 text-white mr-1 tagsize">{{ getSizeTypeString(item) }}</span>
                <span v-if="isLowerCommisionItem(item)" class="small p-1 text-white mr-1 taglower">{{ t('特別販売手数料（5%）') }}</span>
                <span v-if="isSmallAndLight(item)" class="small p-1 text-white mr-1 tagsal">{{ t('小型軽量') }}</span>
                <span v-if="item.variations != void 0 && item.variations.length > 0" class="small p-1 text-white mr-1 tagvariation">{{ t('バリエーション有') }}</span>
                <b-row>
                  <b-col>
                    <h6 class="h6 text-uppercase text-secondary mb-1">Asin<p>{{ item.asin }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-uppercase text-secondary mb-1">{{ t('JAN') }}<p>{{ item.eanList ? item.eanList[0] : "-" }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-secondary mb-1">{{ t('取り扱い開始日') }}<p>{{ item | listedSinceString }}</p></h6>
                  </b-col>
                </b-row>
                <div>
                  <h6 class="h6 text-secondary">
                    {{ t('ランキング') }}
                    <p>
                      <template v-if="item.salesRanks">
                        <span v-if="item.salesRankReference >= 0 && item.categoryTree && item.categoryTree.find(c => item.salesRanks[c.catId]) == void 0">
                          {{ t('{0}位', [getLastKeepaHistory(item.csv[3])]) }}
                          <template v-if="item.categoryTree">
                            [{{ getCategoryName(item.salesRankReference, item.categoryTree) }}]
                          </template>
                        </span>
                        <template v-if="item.categoryTree">
                          <span v-for="(category, i) in item.categoryTree.filter(c => item.salesRanks[c.catId])" :key="i">
                            {{ i > 0 ? "、" : "" }}
                            {{
                              t('{0}位', [getLastKeepaHistory(item.salesRanks[category.catId])])
                            }}[{{
                              getCategoryName(category.catId, item.categoryTree)
                            }}]
                          </span>
                        </template>
                      </template>
                    </p>
                  </h6>
                </div>
                <b-row>
                  <b-col>
                    <h6 class="h6 text-secondary mb-1">{{ t('梱包サイズ') }}<p>{{ createProductInfo(item).packageSizeString }}</p></h6>
                  </b-col>
                  <b-col>
                    <h6 class="h6 text-secondary mb-1">{{ t('容積重量') }}<small>{{ t('（0.5kg単位切上）') }}</small><p>{{ createProductInfo(item).volumetricWeightString }}</p></h6>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <price-info v-if="item.title != void 0" :item="item" :subscriber="subscriber" class="text-sm mt-5" :collapsekey="i"></price-info>
          <shop-list v-if="noshoplist == void 0 && item.hasOwnProperty('eanList')" :item="item" class="text-sm grid-hr" :collapsekey="i" :usdJpy="usdJpy"></shop-list>
        </div>

        <!-- Hide this on desktop ( above 576px ) -->
        <div class="d-flex d-md-none flex-wrap">
          <div class="w-100 d-flex justify-content-end">
            <div v-if="subscriber === true" class="ml-2"><b-button size="sm" class="btn bg-eresa text-white aianalysis" @click="analysisTitle(item.title)">{{ t('AI分析') }} <font-awesome-icon icon="fa-solid fa-brain" style="color: #fff;" /></b-button></div>
            <lock-pro-button v-else-if="subscriber === false" text="AI分析" icon="fa-solid fa-brain" class="ml-2" sm></lock-pro-button>
          </div>
          <b-row class="mb-4 w-100">
            <b-col cols="8">
              <product-image :item="item" :favorite="nolink == void 0 || login != void 0 ? 'favorite' : undefined" sm class="text-center"></product-image>
            </b-col>
            <b-col cols="4">
              <b-spinner v-if="loadingIndex === i" variant="primary" small class="mr-2"></b-spinner>
              <b-icon icon="graph-up" @click="graphClick(item, i)"></b-icon>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <router-link v-if="nolink == void 0" :to="{ name: 'Detail', params: { asin: item.asin }, query: { domain: linkDomain(item.domainId) } }" class="text-eresa d-block mb-3" target="_blank">
                <h6 class="h6">{{ item.title }}</h6>
              </router-link>
              <a v-else href="#" class="text-eresa d-block mb-3">
                <h6 class="h6">{{ item.title }}</h6>
              </a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row class="text-sm">
                <b-col cols="12" class="mb-2">
                  <price-table-sm :item="item" :collapsekey="i" :subscriber="subscriber" :nolink="nolink"></price-table-sm>
                </b-col>
                <b-col>
                  <span v-if="isPremier(item)" class="small p-1 text-white mr-1 tagpremier">{{ t('プレミア') }}</span>
                  <span v-if="getSizeTypeString(item) != ''" class="small p-1 mr-1 text-white tagsize">{{ getSizeTypeString(item) }}</span>
                  <span v-if="isLowerCommisionItem(item)" class="small p-1 text-white mr-1 taglower">{{ t('特別販売手数料（5%）') }}</span>
                  <span v-if="isSmallAndLight(item)" class="small p-1 text-white mr-1 tagsal">{{ t('小型軽量') }}</span>
                  <span v-if="item.variations != void 0 && item.variations.length > 0" class="small p-1 text-white mr-1 tagvariation">{{ t('バリエーション有') }}</span>
                  <b-row class="mt-1">
                    <b-col class="pr-0">
                      <h6 class="h6 text-uppercase text-secondary mb-1">Asin<p>{{ item.asin }}</p></h6>
                    </b-col>
                    <b-col class="px-0">
                      <h6 class="h6 text-uppercase text-secondary mb-1">Jan<p>{{ item.eanList ? item.eanList[0] : "-" }}</p></h6>
                    </b-col>
                    <b-col class="pl-0">
                      <h6 class="h6 text-secondary mb-1">{{ t('取り扱い開始日') }}<p>{{ item | listedSinceString }}</p></h6>
                    </b-col>
                  </b-row>
                  <div>
                    <h6 class="h6 text-secondary">
                      {{ t('ランキング') }}
                      <p>
                        <template v-if="item.salesRanks">
                          <span v-if="item.salesRankReference >= 0 && item.categoryTree && item.categoryTree.find(c => item.salesRanks[c.catId]) == void 0">
                            {{ t('{0}位', [getLastKeepaHistory(item.csv[3])]) }}
                            <template v-if="item.categoryTree">
                              [{{ getCategoryName(item.salesRankReference, item.categoryTree) }}]
                            </template>
                          </span>
                          <template v-if="item.categoryTree">
                            <span v-for="(category, i) in item.categoryTree.filter(c => item.salesRanks[c.catId])" :key="i">
                              {{ i > 0 ? "、" : "" }}
                              {{
                                t('{0}位', [getLastKeepaHistory(item.salesRanks[category.catId])])
                              }}[{{
                                getCategoryName(category.catId, item.categoryTree)
                              }}]
                            </span>
                          </template>
                        </template>
                      </p>
                    </h6>
                  </div>
                  <b-row class="mt-1">
                    <b-col class="pr-0">
                      <h6 class="h6 text-secondary mb-1">{{ t('梱包サイズ') }}<p>{{ createProductInfo(item).packageSizeString }}</p></h6>
                    </b-col>
                    <b-col class="pr-0">
                      <h6 class="h6 text-secondary mb-1">{{ t('容積重量') }}<small>{{ t('（0.5kg単位切上）') }}</small><p>{{ createProductInfo(item).volumetricWeightString }}</p></h6>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <price-info v-if="item.title != void 0" :item="item" :subscriber="subscriber" class="text-sm" :collapsekey="i"></price-info>
          <shop-list v-if="noshoplist == void 0 && item.hasOwnProperty('eanList')" :item="item" class="text-sm grid-hr" :collapsekey="i" :usdJpy="usdJpy"></shop-list>
        </div>
      </b-row>
      <b-modal :id="`graphs_${i}`" hide-header hide-footer :key="`graphs${i}`" size="xl">
        <div class="d-flex justify-content-between">
          <div class="font-weight-bold">{{ t('期間別グラフ') }}</div><b-icon icon="x" @click="$bvModal.hide(`graphs_${i}`)"></b-icon>
        </div>
        <!-- Hide this on mobile ( less than 576px ) -->
        <div class="d-none d-md-flex mt-3">
          <div class="d-flex flex-column">
            <div class="small">{{ t('過去30日') }}</div>
            <img :src="item.keepaGraphUrl_30" class="w-100" />
          </div>
          <div class="d-flex flex-column ml-3">
            <div class="small">{{ t('過去90日') }}</div>
            <img :src="item.keepaGraphUrl_90" class="w-100" />
          </div>
        </div>
        <div class="d-none d-md-flex mt-3">
          <div class="d-flex flex-column">
            <div class="small">{{ t('過去180日') }}</div>
            <img :src="item.keepaGraphUrl_180" class="w-100" />
          </div>
          <div class="d-flex flex-column ml-3">
            <div class="small">{{ t('過去365日') }}</div>
            <img :src="item.keepaGraphUrl_365" class="w-100" />
          </div>
        </div>
        <!-- Hide this on desktop ( above 576px ) -->
        <div class="d-flex d-md-none flex-column">
          <div class="small mt-3">{{ t('過去30日') }}</div>
          <img :src="item.keepaGraphUrl_30" class="w-100" />
          <div class="small mt-3">{{ t('過去90日') }}</div>
          <img :src="item.keepaGraphUrl_90" class="w-100" />
          <div class="small mt-3">{{ t('過去180日') }}</div>
          <img :src="item.keepaGraphUrl_180" class="w-100" />
          <div class="small mt-3">{{ t('過去365日') }}</div>
          <img :src="item.keepaGraphUrl_365" class="w-100" />
        </div>
      </b-modal>
    </template>
    <ai-chat-window ref="aichat"></ai-chat-window>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import Utils from "@/mixins/utils";
import ProductImage from "@/components/Product/ProductImage";
import PriceTableSm from "@/components/Product/PriceTableSm.vue";
import ShopList from "@/components/Product/ShopList";
import PriceInfo from "@/components/Product/PriceInfo";
import AiChatWindow from "@/components/AiChatWindow";
import LockProButton from "@/components/LockProButton.vue";
import AuthUtil from "@/mixins/authutil";
import { AIChat } from "@/mixins/AIChat";
import { ProductInfo } from "@/mixins/ProductInfo";

export default {
  name: "ResultListLite",
  props: ['asins', 'domainId', 'nolink', 'noshoplist', 'login'],
  data: function () {
    return {
      MEMO_LENGTH: 50,
      list: [],
      subscriber: null,
      usdJpy: null,
      count: 0,
      loaded: 0,
      loadingIndex: null,
    };
  },
  components: {
    ProductImage,
    PriceTableSm,
    ShopList,
    PriceInfo,
    AiChatWindow,
    LockProButton,
  },
  mixins: [Utils, AuthUtil],
  filters: {
    listedSinceString(item) {
      return new ProductInfo(item).listedSinceString;
    },
  },
  watch: {},
  computed: {},
  created() {},
  async mounted() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser) {
      this.subscriber = await this.isSubscriber();
    }
    this.usdJpy = await this.getUsdJpy();
    if (this.asins != void 0) {
      this.count = this.asins.length;
      await this.add(this.asins, this.domainId, true);
    }
  },
  methods: {
    async getProduct(asin, domain) {
      let item = null;
      if (item == void 0) {
        item = await API.graphql(
          graphqlOperation(queries.getProductDetail, { asin, domain, isLite: true })
        ).then(async (rslt) => {
          const item = JSON.parse(rslt.data.getProductDetail.json)[0];
          return item;
        });
      }
      return item;
    },
    linkDomain(domainId) {
      return this.isDefaultDomain(domainId) ? void 0 : domainId;
    },
    async add(asins, domainId, clearlist = false) {
      if (asins == void 0 || domainId == void 0) return;
      if (clearlist) {
        this.list = [];
        this.count = 0;
      }
      this.list.unshift(...asins.map(asin => ({ asin, domainId })));
      this.count += asins.length;
      this.loadProducts(asins.length);
    },
    async addProducts(items, clearlist = false) {
      if (items == void 0) return;
      if (clearlist) {
        this.list = [];
        this.count = 0;
      }
      this.list.unshift(...items.map(p => ({ asin: p.asin, domainId: p.domainId })));
      this.count += items.length;
      this.loadProducts(items.length);
    },
    async loadProducts(length) {
      this.list.slice(0, length).forEach(async (item) => {
        this.getProduct(item.asin, item.domainId).then((res) => {
          Object.keys(res).forEach((key) => this.$set(item, key, res[key]));
          if (this.count > 0) {
            this.loaded++;
            if (this.loaded === this.count) {
              this.$emit('loaded');
            }
          }
        });
      });
    },
    clear() {
      this.list = [];
    },
    createCsvData() {
      const data = this.list.map(item => {
        const p = new ProductInfo(item);
        const ranking = p.ranking;
        const size = p.packageSizeStrings;
        const volumetricWeight = p.volumetricWeightString;
        const csv = this.getCsvNameIndexes();
        const memo = [
          this.isPremier(item) ? this.t('プレミア') : '',
          this.isSmallAndLight(item) ? this.t('小型軽量') : ''
        ].filter(e => e !== '').join(',');
        return [
          item.imagesCSV == void 0 ? '' : `https://images-na.ssl-images-amazon.com/images/I/${item.imagesCSV.split(',')[0]}`, //画像
          p.item.title, //商品名
          p.item.asin, //ASIN
          p.eanString, //JAN/EANコード
          ranking.ranking > 0 ? ranking.ranking : '-', //ランキング
          this.getCategoryName(ranking.catId, p.item.categoryTree), //カテゴリ
          p.priceString(csv.AMAZON, true), //Amazon本体価格
          p.priceString(csv.BUY_BOX_SHIPPING, true), //カート価格
          ProductInfo.price(item.domainId, item.stats.current[csv.BUY_BOX_SHIPPING] - this.getCommission(item, item.stats.current[csv.BUY_BOX_SHIPPING]) - this.getFBAFee(item, item.stats.current[csv.BUY_BOX_SHIPPING]), true), //カート損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.BUY_BOX_SHIPPING] - this.getCommission(item, item.stats.current[csv.BUY_BOX_SHIPPING]), true), //カート損益分岐点の価格（自社配送）
          p.priceString(csv.NEW, true), //新品最安値
          ProductInfo.price(item.domainId, item.stats.current[csv.NEW] - this.getCommission(item, item.stats.current[csv.NEW]) - this.getFBAFee(item, item.stats.current[csv.NEW]), true), //新品最安値損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.NEW] - this.getCommission(item, item.stats.current[csv.NEW]), true), //新品最安値損益分岐点の価格（自社配送）
          p.priceString(csv.USED, true), //中古最安値
          ProductInfo.price(item.domainId, item.stats.current[csv.USED] - this.getCommission(item, item.stats.current[csv.USED]) - this.getFBAFee(item), true), //中古最安値損益分岐点の価格（FBA）
          ProductInfo.price(item.domainId, item.stats.current[csv.USED] - this.getCommission(item, item.stats.current[csv.USED]), true), //中古最安値損益分岐点の価格（自社配送）
          p.listedSinceString, //取り扱い開始日
          p.item.brand, //メーカー
          size[1], //サイズ_幅
          size[0], //サイズ_奥行
          size[2], //サイズ_高さ
          size[3], //梱包重量
          volumetricWeight, //容積重量
          memo, //備考
        ].map(s => this.encloseString(s == void 0 || s.length <= 0 ? '-' : s)).join(',')
      });
      const title = this.t('csvheaderlite');
      return `${title}\n${data.join('\n')}`;
    },
    encloseString(str) {
      const s = str.toString();
      if (s.includes(',')) {
        return `"${s.replace(/"/g, '""')}"`;
      }
      return s;
    },
    async graphClick(item, i) {
      //グラフのキャッシュ作成を行ってから表示
      this.loadingIndex = i;
      try {
        await API.graphql(graphqlOperation(mutations.makeGraphs, { asin: item.asin, domain: item.domainId }));
        for (const r of [30, 90, 180, 365]) {
          const name = r === 90 ? `${item.asin}.png` : `${item.asin}_${r}.png`
          const path = item.domainId == this.$store.getters.getComDomain ? `${item.domainId}/png/${name}` : `png/${name}`;
          await Storage.get(path).then(url => (item[`keepaGraphUrl_${r}`] = url));
        }
        this.$bvModal.show(`graphs_${i}`);
      }
      finally {
        this.loadingIndex = null;
      }
    },
    analysisTitle(title) {
      const message = AIChat.createMessageFromTitle(title);
      this.$refs.aichat.show(message);
    },
    searchImage(item) {
      if (item.imagesCSV != void 0) {
        const route = this.$router.resolve({ name: 'Aibuy', query: { image: item.imagesCSV.split(',')[0] } });
        window.open(route.href, '_blank');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*
.chartStyle {
  min-width: 300px;
  max-height: 180px;
}
*/
.tagpremier {
  background-color: #999237;
}
.tagsize {
  background-color: #3e9937;
}
.tagsal {
  background-color: #5eb957;
}
.taglower {
  background-color: #90af8f;
}
.tagvariation {
  background-color: #d9777e;
}
h6 {
  font-size: 10pt;
}
.aianalysis, .imgsearch {
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}

.grid-hr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
    padding-bottom: 1em;
}

@media (max-width: 575.98px) {
  h6 {
    font-size: 0.72rem;
  }
}
</style>
