export default {
  "Stripe情報管理": "Stripe Information Management",
  "メールアドレスを入力してください。": "Please enter email address",
  "メールアドレス": "Email Address",
  "Stripe情報を表示": "Show Stripe information",
  "Stripeアカウント": "Stripe account",
  "StripeカスタマーID": "Stripe customer ID",
  "{0}を入力してください。": "Please enter {0}",
  "StripeプランID": "Stripe plan ID",
  "買い切りプラン": "buy-one-get-one-free plan",
  "StripeサブスクリプションID": "Stripe subscription ID",
  "Stripe期間終了日": "Stripe period end date",
  "{0}を日付形式で入力してください。": "Enter {0} in date format",
  "招待ユーザーID": "Invited user ID",
  "招待ユーザーに報酬を付与": "Reward invited users",
  "アフィリエイト報酬": "Affiliate commissions",
  "報酬計算": "Calculate commissions",
  "Kitにタグを登録": "Register a tag in Kit",
  "登録先": "Registered at",
  "タグ": "tag",
  "有料会員としてStripe情報を登録": "Register a stripe information as a paying member",
  "登録": "Register",
  "キャンセル": "Cancel",
  "登録します。よろしいですか？": "Do you want to register?",
  "登録が完了しました。": "Registration has been completed.",
  "日本版": "Japanese version",
  "米国版": "U.S. version",
  "無料会員": "Free membership",
  "有料会員": "Paid membership",
  "有料会員＋年間会員（特別プラン）（日本版のみ）": "Paid membership + Annual membership (special plan) (Japanese version only)",
  "ERESA未登録のメールアドレスです。": "This is an ERESA unregistered email address.",
  "※有料会員の招待ユーザーのみ表示": "*Displayed only for invited users of paying members",
  "※Stripeで契約が複数ある場合は、無効な契約をキャンセルしてください。": "*If you have more than one contract with Stripe, please cancel the invalid contract.",
  "ERESAに対応するカスタマーIDの関連付けがある状態でStripeのキャンセルを行うと、関連付けが削除され無料会員に戻ります。": "If you cancel Stripe while there is a corresponding Customer ID association with ERESA, the association will be deleted and you will revert to a free membership.",
}
