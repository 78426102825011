<template>
  <div class="page-content">
    <div class="container">
      <manual-link>{{ t('Stripe情報管理') }}</manual-link>

      <div>{{ t('メールアドレスを入力してください。') }}</div>
      <b-input-group class="inputgroup">
        <b-form-input type="email" v-model='email' :placeholder="t('メールアドレス')"></b-form-input>
        <b-input-group-append>
          <b-button class="btn bg-eresa text-white px-3" type="button" @click="showStripeInfo" :disabled="email.length === 0">{{ t('Stripe情報を表示') }}</b-button>
        </b-input-group-append>
        <b-spinner v-if="loading" variant="primary" small class="ml-2"></b-spinner>
      </b-input-group>
      <b-alert :show="errorMassageLoad != ''" variant="danger">{{ errorMassageLoad }}</b-alert>

      <div class="mt-4" v-if="stripeInfo != void 0">
        <div class="container stripeinfo-table mt-2">
          <div class="stripeinfo-key">username</div>
          <div class="stripeinfo-value">{{ stripeInfo.ui.owner }}</div>
          <div class="stripeinfo-key">{{ t('Stripeアカウント') }}</div>
          <div class="stripeinfo-value">
            <b-dropdown :text="stripeAccountList[stripeDomainList.indexOf(stripeInfo.ui.stripeDomain)]" split-variant="outline-primary" class="dropdown-eresa mt-2">
              <b-dropdown-item v-for="(item, i) in stripeAccountList" :key="i" @click="stripeInfo.ui.stripeDomain = stripeDomainList[i]">{{ item }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="stripeinfo-key">{{ t('StripeカスタマーID') }}</div>
          <div class="stripeinfo-value">
            <b-form-input type="text" v-model="stripeInfo.si.customer" placeholder="cus_xxxxxxxxxxxxxx"></b-form-input>
            <div v-if="hasErrors.customer" class="error">{{ t('{0}を入力してください。', [t('StripeカスタマーID')]) }}</div>
          </div>
          <div class="stripeinfo-key">{{ t('StripeプランID') }}</div>
          <div class="stripeinfo-value">
            <b-form-input type="text" v-model="stripeInfo.si.planId" placeholder="price_xxxxxxxxxxxxxxxxxxxxxxxx"></b-form-input>
            <div v-if="hasErrors.planId" class="error">{{ t('{0}を入力してください。', [t('StripeプランID')]) }}</div>
          </div>
          <div class="stripeinfo-key">{{ t('買い切りプラン') }}</div>
          <div class="stripeinfo-value">
            <b-form-checkbox v-model="checkPermanent" @change="stripeInfo.si.subscription = checkPermanent ? '' : stripeInfo.si.subscription;stripeInfo.si.periodEnd = checkPermanent ? '' : stripeInfo.si.periodEnd;"></b-form-checkbox>
          </div>
          <div class="stripeinfo-key">{{ t('StripeサブスクリプションID') }}</div>
          <div class="stripeinfo-value">
            <b-form-input type="text" v-model="stripeInfo.si.subscription" :disabled="checkPermanent" placeholder="sub_xxxxxxxxxxxxxxxxxxxxxxxx"></b-form-input>
            <div v-if="hasErrors.subscription" class="error">{{ t('{0}を入力してください。', [t('StripeサブスクリプションID')]) }}</div>
          </div>
          <div class="stripeinfo-key">{{ t('Stripe期間終了日') }}</div>
          <div class="stripeinfo-value">
            <b-form-input type="text" v-model="stripeInfo.si.periodEnd" :disabled="checkPermanent" placeholder="YYYY-MM-DDTHH:mm:ssZ"></b-form-input>
            <div v-if="hasErrors.periodEnd" class="error">{{ t('{0}を入力してください。', [t('Stripe期間終了日')]) }}</div>
            <div v-if="hasErrors.periodEndFormat" class="error">{{ t('{0}を日付形式で入力してください。', [t('Stripe期間終了日')]) }}</div>
          </div>
          <div class="stripeinfo-key"><div>{{ t('招待ユーザーID') }}</div><small>{{ t('※有料会員の招待ユーザーのみ表示') }}</small></div>
          <div class="stripeinfo-value">{{ stripeInfo.affuser }}</div>
          <div class="stripeinfo-key">{{ t('招待ユーザーに報酬を付与') }}</div>
          <div class="stripeinfo-value">
            <b-form-checkbox v-model="checkAff" :disabled="stripeInfo.affuser == void 0" @change="affcommission = checkAff ? affcommission : 0"></b-form-checkbox>
          </div>
          <div class="stripeinfo-key">{{ t('アフィリエイト報酬') }}</div>
          <div class="stripeinfo-value">
            <b-input-group class="inputgroup">
              <b-form-input type="number" min="0" v-model="affcommission" :disabled="!checkAff"></b-form-input>
              <b-input-group-append>
                <b-button class="btn bg-eresa text-white px-3" type="button" :disabled="!checkAff" @click="showAffPrice()">{{ t('報酬計算') }}</b-button>
              </b-input-group-append>
              <b-spinner v-if="processingCommission" variant="primary" small class="ml-2"></b-spinner>
            </b-input-group>
          </div>
          <div class="stripeinfo-key">{{ t('Kitにタグを登録') }}</div>
          <div class="stripeinfo-value">
            <b-form-checkbox v-model="checkKit"></b-form-checkbox>
          </div>
          <div class="stripeinfo-key">{{ t('登録先') }}</div>
          <div class="stripeinfo-value">
            <b-dropdown  :text="kitForms[selectedKitForm]" split-variant="outline-primary" class="dropdown-eresa mt-2" :disabled="!checkKit">
              <b-dropdown-item v-for="(item, i) in kitForms" :key="i" @click="selectedKitForm = i; selectedKitTag = 0">{{ item }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="stripeinfo-key">{{ t('タグ') }}</div>
          <div class="stripeinfo-value">
            <b-dropdown  :text="kitTags[selectedKitForm][selectedKitTag]" split-variant="outline-primary" class="dropdown-eresa mt-2" :disabled="!checkKit">
              <b-dropdown-item v-for="(item, i) in kitTags[selectedKitForm]" :key="i" @click="selectedKitTag = i">{{ item }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="small">
          {{ t('※Stripeで契約が複数ある場合は、無効な契約をキャンセルしてください。') }}
        </div>
        <div class="small" style="margin-left: 1em">
          {{ t('ERESAに対応するカスタマーIDの関連付けがある状態でStripeのキャンセルを行うと、関連付けが削除され無料会員に戻ります。') }}
        </div>
        <b-button class="btn bg-eresa text-white px-3 mt-4" type="button" @click="validateRegister()">{{ t('有料会員としてStripe情報を登録') }}</b-button>
        <b-alert :show="errorMassageRegister != ''" variant="danger">{{ errorMassageRegister }}</b-alert>
        <b-spinner v-if="processing" variant="primary" small class="ml-2"></b-spinner>
      </div>
    </div>

    <b-modal ref="registerConfirm" @ok="register" :title="t('登録')" :ok-title="t('登録')" :cancel-title="t('キャンセル')">
      {{ t('登録します。よろしいですか？') }}
    </b-modal>
    <b-modal ref="registerDone" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true">
      {{ t('登録が完了しました。') }}
    </b-modal>
  </div>
</template>

<script>
import ManualLink from '@/components/ManualLink.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { EresaApis } from '@/mixins/EresaApis';

const KITFORM_JP = 0;
const KITTAG_FREE = 0;
const KITTAG_PROSPECIAL = 2;

export default {
  name: 'StripeInfo',
  components: {
    ManualLink,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      email: '',
      stripeInfo: null,
      stripeDomainList: ['ja', 'io'],
      stripeAccountList: ['eresa.jp', 'eresa.io'],
      checkPermanent: false,
      checkAff: false,
      affcommission: 0,
      checkKit: false,
      kitForms: [this.t('日本版'), this.t('米国版')],
      selectedKitForm: KITFORM_JP,
      kitTags: [[this.t('無料会員'), this.t('有料会員'), this.t('有料会員＋年間会員（特別プラン）（日本版のみ）')], [this.t('無料会員'), this.t('有料会員')]],
      selectedKitTag: KITTAG_FREE,
      loading: false,
      processingCommission: false,
      processing: false,
      errorMassageLoad: '',
      errorMassageRegister: '',
      hasErrors: {
        customer: false,
        planId: false,
        subscription: false,
        periodEnd: false,
        periodEndFormat: false,
      },
      affPlans: null,
    }
  },
  async mounted() {
    await this.validateAdmin();
  },
  methods: {
    async showStripeInfo() {
      this.stripeInfo = null;
      this.checkPermanent = false;
      this.checkAff = false;
      this.affcommission = 0;
      this.checkKit = false;
      this.selectedKitForm = KITFORM_JP;
      this.selectedKitTag = KITTAG_FREE;
      this.errorMassageLoad = '';
      this.errorMassageRegister = '';
      this.hasErrors.customer = false;
      this.hasErrors.planId = false;
      this.hasErrors.subscription = false;
      this.hasErrors.periodEnd = false;
      this.hasErrors.periodEndFormat = false;
      this.loading = true;
      try {
        try {
          this.stripeInfo = await EresaApis.getStripeInfo(this.email);
          if (this.stripeInfo?.si?.updatedAt == void 0) {
            this.stripeInfo.ui.stripeDomain = 'ja';
            this.stripeInfo.ui.stripeCustomer = '';
            this.stripeInfo.si.customer = '';
            this.stripeInfo.si.planId = '';
            this.stripeInfo.si.subscription = '';
            this.stripeInfo.si.periodEnd = '';
          }
        }
        catch {
          this.errorMassageLoad = this.t('ERESA未登録のメールアドレスです。');
          return;
        }
      }
      finally {
        this.loading = false;
      }
    },
    validateRegister() {
      this.hasErrors.customer = false;
      this.hasErrors.planId = false;
      this.hasErrors.subscription = false;
      this.hasErrors.periodEnd = false;
      this.hasErrors.periodEndFormat = false;
      let count = 0;
      if (this.stripeInfo.si.customer.length <= 0) {
        this.hasErrors.customer = true;
        count++;
      }
      if (this.stripeInfo.si.planId.length <= 0) {
        this.hasErrors.planId = true;
        count++;
      }
      if (!this.checkPermanent) {
        if (this.stripeInfo.si.subscription.length <= 0) {
          this.hasErrors.subscription = true;
          count++;
        }
        if (this.stripeInfo.si.periodEnd.length <= 0) {
          this.hasErrors.periodEnd = true;
          count++;
        }
        else {
          const date = new Date(this.stripeInfo.si.periodEnd);
          if (isNaN(date.getTime())) {
            this.hasErrors.periodEndFormat = true;
            count++;
          }
          else {
            this.stripeInfo.si.periodEnd = date.toISOString();
          }
        }
      }
      if (count > 0) {
        return;
      }
      this.$refs.registerConfirm.show();
    },
    async register() {
      this.errorMassageRegister = '';
      this.processing = true;
      try {
        const kitTarget = this.checkKit ? (this.selectedKitForm == KITFORM_JP ? 'jp' : 'com') : void 0;
        const kitPro = this.selectedKitTag != KITTAG_FREE;
        const kitSpecialTagJp = this.selectedKitTag == KITTAG_PROSPECIAL;
        try {
          await EresaApis.setStripeInfo(
            this.stripeInfo.ui.owner,
            this.stripeInfo.ui.stripeDomain,
            this.stripeInfo.si,
            this.checkAff ? parseInt(this.affcommission) : void 0,
            kitTarget,
            kitPro,
            kitSpecialTagJp
          );
        }
        catch {
          this.errorMassageRegister = this.t('登録に失敗しました。');
          return;
        }
      }
      finally {
        this.processing = false;
      }
      this.$refs.registerDone.show();
    },
    async showAffPrice() {
      this.processingCommission = true;
      try {
        if (this.affPlans == void 0) {
          this.affPlans = await EresaApis.getAffActivePlans();
        }
        const r = this.affPlans.find(r => r['plan_id'] === this.stripeInfo.si.planId);
        this.affcommission = r == void 0 ? 0 : r['commission'];
      }
      finally {
        this.processingCommission = false;
      }
    }
  },
}
</script>

<style scoped>
.inputgroup {
  max-width: 480px;
}

.stripeinfo-table {
  display: grid;
  grid-template-columns: 14em 24em;
}

.stripeinfo-key {
  margin: auto 0px;
}

.stripeinfo-value {
  margin: 4px 0px;
}

.error {
  color: red;
}
</style>
