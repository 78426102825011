import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';

/**
 * EresaApis
 */
export class EresaApis {
  /**
   * セラー情報取得
   * @param {string} sellerId
   * @param {boolean} isDetail
   * @param {string} domainId
   * @returns
   */
  static async getSeller(sellerId, isDetail, domain) {
    return await EresaApis._keepaOperation('getSeller', { sellerId, isDetail, domain });
  }

  /**
   * トラッキング追加
   * @param {string} asin
   * @param {number} ttl トラッキング期限（1年=24*365）
   * @param {array} thresholdValues
   * @param {array} notifyIf
   * @param {string} metaData
   * @param {string} domainId
   * @param {string} userName
   * @returns
   */
  static async addTracking(asin, ttl, thresholdValues, notifyIf, metaData, domain) {
    return await EresaApis._keepaOperation('addTracking', { asin, ttl, thresholdValues, notifyIf, metaData, domain });
  }

  /**
   * トラッキング取得
   * @param {string} asin
   * @param {string} domainId
   * @returns
   */
  static async getTracking(asin, domain) {
    return await EresaApis._keepaOperation('getTracking', { asin, domain });
  }

  /**
   * トラッキングリスト取得
   * @param {string} domainId
   * @returns
   */
  static async getTrackingList(domain, countPerPage = 0, page = 1) {
    return await EresaApis._keepaOperation('getTrackingList', { domain, countPerPage, page });
  }

  /**
   * トラッキング削除
   * @param {string} asin
   * @param {string} domainId
   * @returns
   */
  static async removeTracking(asin, domain) {
    return await EresaApis._keepaOperation('removeTracking', { asin, domain });
  }

  static async _keepaOperation(operation, args) {
    const query = await API.graphql(graphqlOperation(mutations.keepaOperation, {
      operation,
      args: JSON.stringify(args)
    }));
    return query.data.keepaOperation == void 0 ? {} : JSON.parse(query.data.keepaOperation);
  }

  /**
   * ERESA拡張機能の使用可否取得
   * @returns
   */
  static async getEresaExpandedStatus() {
    return await EresaApis._adminOperation('getEresaExpandedStatus', {});
  }

  /**
   * ERESA拡張機能の使用可否更新
   * @param {boolean} enable ERESA拡張機能の使用可否
   * @returns
   */
  static async updateEresaExpandedStatus(enable) {
    return await EresaApis._adminOperation('updateEresaExpandedStatus', { enable });
  }

  /**
   * Keepaのトークン履歴取得
   * @returns トークン履歴
   */
  static async getKeepaTokenStatus() {
    return await EresaApis._adminOperation('getKeepaTokenStatus', {});
  }

  /**
   * 固定のお知らせ情報を取得します。
   * @returns 固定のお知らせ情報の内容。
   */
  static async getEresaNotice() {
    return await EresaApis._adminOperation('getEresaNotice', {});
  }

  /**
   * 固定のお知らせ情報を更新します。
   * @param {boolean} pub 公開／未公開
   * @param {string} title タイトル
   * @param {string} link リンク先URL
   * @returns
   */
  static async updateEresaNotice(pub, title, link) {
    return await EresaApis._adminOperation('updateEresaNotice', { pub, title, link });
  }

  /**
   * 年間プランユーザーのみの機能を強制解放します。
   * @param {string[]} mails ユーザーのメールアドレス
   * @returns 年間プランユーザーのみの機能を強制解放したメールアドレスリスト
   */
  static async addEresaProYearMails(mails) {
    return await EresaApis._adminOperation('addEresaProYearMails', { mails });
  }

  /**
   * 年間プランユーザーのみの機能の開放を無効にします。
   * @param {string[]} mails ユーザーのメールアドレス
   * @returns 年間プランユーザーのみの機能を強制解放したメールアドレスリスト
   */
  static async removeEresaProYearMails(mails) {
    return await EresaApis._adminOperation('removeEresaProYearMails', { mails });
  }

  /**
   * 年間プランユーザーのみの機能を強制解放したメールアドレスリストを取得します。
   * @returns 年間プランユーザーのみの機能を強制解放したメールアドレスリスト
   */
  static async getEresaProYearMails() {
    return await EresaApis._adminOperation('getEresaProYearMails', {});
  }

  /**
   * stripe関連情報を取得します。
   * @param {string} email 
   * @returns { ui, si, affuser }
   */
  static async getStripeInfo(email) {
    return await EresaApis._adminOperation('getStripeInfo', { email });
  }

  /**
   * stripe関連情報を設定します。
   * @param {string} username
   * @param {string} stripeDomain
   * @param {any} si
   * @param {number | undefined} affcommission
   * @param {string | undefined} kitTarget
   * @param {boolean} kitPro
   * @param {boolean} kitSpecialTagJp
   */
  static async setStripeInfo(username, stripeDomain, si, affcommission, kitTarget, kitPro, kitSpecialTagJp) {
    return await EresaApis._adminOperation('setStripeInfo', { username, stripeDomain, si, affcommission, kitTarget, kitPro, kitSpecialTagJp });
  }

  static async _adminOperation(operation, args) {
    const query = await API.graphql(graphqlOperation(mutations.adminOperation, {
      operation,
      args: JSON.stringify(args)
    }));
    return query.data.adminOperation == void 0 ? {} : JSON.parse(query.data.adminOperation);
  }

  /**
   * アフィリエイトのプラン一覧取得
   * @returns プラン一覧
   */
  static async getAffActivePlans() {
    return await EresaApis._affiliateOperation('getAffActivePlans', {});
  }

  static async _affiliateOperation(operation, args) {
    const query = await API.graphql(graphqlOperation(mutations.affiliateOperation, {
      operation,
      args: JSON.stringify(args)
    }));
    return query.data.affiliateOperation == void 0 ? {} : JSON.parse(query.data.affiliateOperation);
  }
}
