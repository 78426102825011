<template>
  <div class="page-content">
    <div class="container pb-3">
      <h1 class="font-weight-bold pb-5 d-none d-sm-block">{{ t('アカウント情報') }}</h1>
      <div class="font-weight-bold pb-5 title-sm d-sm-none">{{ t('アカウント情報') }}</div>

      <template v-if="isInitializing">
         <b-spinner variant="primary"></b-spinner>
      </template>
      <template v-else>
        <div v-if="admin">
          {{ t('管理用ユーザーでログインしています。') }}
        </div>
        <template v-if="subscriberType != void 0">
          <div v-if="subscriberType.isPermanent">
            {{ t('会員種別：ERESA PRO会員（永久会員）') }}
          </div>
          <div v-else-if="subscriberType.name === 'EresaSubscriber'">
            {{ t('会員種別：ERESA PRO会員（有料会員）') }}
          </div>
          <div v-else-if="subscriberType.name === 'EresaSubscriberWithApple'">
            {{ t('会員種別：ERESA PRO会員（アプリ版から課金された有料会員）') }}
          </div>
          <div v-else>
            {{ t('会員種別：無料会員') }}
          </div>

          <div v-if="subscriberType.name === 'EresaSubscriber' && !subscriberType.isPermanent">
            <div>
              <b-button @click="checkout" class="btn my-2 bg-eresa">{{ t('プランの確認及び変更') }}</b-button>
            </div>
          </div>
          <div v-else-if="!subscriberType.subscriber">
            <div>
              <b-button @click="checkout" class="my-2 bg-eresa">{{ t('ERESA PRO版（月額プラン）') }}</b-button>
            </div>
            <div>
              <b-button @click="checkoutYear" class="my-2 bg-eresa">{{ t('ERESA PRO版（年額プラン）※2ヶ月間無料！') }}</b-button>
            </div>
          </div>

          <template v-if="dplusLink != void 0">
            <div v-if="dplusLink.registered_at == void 0">
              <b-button @click="linkToDplus" class="my-2 bg-eresa">{{ t('出品機能（D-plus）を有効化する') }}</b-button>
            </div>
            <div v-else>
              <b-button @click="linkToDplus" class="my-2 bg-eresa">{{ t('出品機能（D-plus）を利用する') }}</b-button>
            </div>
          </template>
        </template>

        <template v-if="subscriberType.eresaExpandedAccessToken != void 0">
          <div class="mt-2">
            <span class="text-eresa">{{ t('ERESA拡張機能アクセストークン') }}: {{ subscriberType.eresaExpandedAccessToken }}</span>
            <b-icon id="account-eresa-expanded-access-token" class="ml-1" icon="clipboard" @click="writeToClipboard(subscriberType.eresaExpandedAccessToken, $event)"></b-icon>
            <b-tooltip ref="tooltipAccessToken" target="account-eresa-expanded-access-token" :show.sync="tooltipAccessToken" placement="top" triggers="click">{{ t('コピーされました') }}</b-tooltip>
          </div>
          <div>{{ t('※chrome拡張機能の一部機能の利用に必要なアクセストークン（毎月更新）です。') }}</div>
        </template>
      </template>

      <div v-if="organization != void 0" class="mt-4 d-flex">
        {{ t('組織種別') }}{{ t('：') }}{{ getOrganizationText() }}
        <b-button @click="showSelectOrganization" class="btn-sm ml-4 bg-eresa" style="font-size: 0.5em">{{ t('変更') }}</b-button>
      </div>
      <select-organization ref="selectOrganization" :initOrganization="organization" @organizationSelected="organizationSelected"></select-organization>

      <b-modal ref="removeUserCompleted" ok-only :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" @ok="signOut">
        {{ t('退会が無事完了しました。またのご利用をお待ちしております。') }}<br/>{{ t('※有料プラン加入の場合、同時に解約となっております。') }}
      </b-modal>

      <div v-if="organization != void 0" class="mt-2 d-flex">
        {{ t('メールアドレス') }}{{ t('：') }}{{ $store.getters.getUser.attributes.email }}
        <b-button @click="changeMailAddress" class="btn-sm ml-4 bg-eresa" style="font-size: 0.5em" :disabled="disabledChangeMailAddress">{{ t('変更') }}</b-button>
        <b-button @click="changePassword" class="btn-sm ml-2 bg-eresa" style="font-size: 0.5em" :disabled="disabledChangeMailAddress">{{ t('パスワード変更') }}</b-button>
      </div>

      <!--
      <div v-if="!isComEresa() && rssview != void 0" class="mt-2 d-flex">
        {{ t('お知らせの表示') }}{{ t('：') }}{{ `${rssview ? t('あり') : t('なし')}` }}
        <b-button @click="changeRssView" class="btn-sm ml-4 bg-eresa" style="font-size: 0.5em">{{ t('変更') }}</b-button>
      </div>
      -->

      <b-button @click="showConfirmRemoveUser" class="mt-4">{{ t('会員情報を削除する') }}</b-button>
      <b-form-checkbox v-model="deleteAllAccounts">{{ t('同一メールアドレスの全てのアカウントを削除') }}</b-form-checkbox>
    </div>
    <loading-screen v-if="isProcessing"></loading-screen>

    <b-modal ref="selectRssview" @ok="rssviewSelected">
      <div class="mt-4">
        <div>{{ t('お知らせの表示を選択してください。') }}</div>
        <div class="mt-2">
          <b-form-group>
            <b-form-radio-group v-model="newRssview" :options="rssviewOptions"></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import * as mutations from "@/graphql/mutations";
import SelectOrganization from "@/components/SelectOrganization.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import AuthUtil from "@/mixins/authutil";
import Utils, { DPLUS } from "@/mixins/utils";
import { UserSettings } from '@/mixins/UserSettings';

const COM_SUCCESS_MONTH_URL = 'https://eresa.io/mz06';
const COM_SUCCESS_YEAR_URL = 'https://eresa.io/78n2';
const JP_SUCCESS_MONTH_URL = 'https://eresa.jp/mfj6';
const JP_SUCCESS_YEAR_URL = 'https://eresa.jp/3vub';

const KEY_RSSVIEW = 'app.rssview';
const PRICEID_CAMPAIGN = 'limited_special_since202309';

export default {
  name: "Account",
  data() {
    return {
      admin: false,
      subscriberType: null,
      organization: null,
      isProcessing: false,
      disabledChangeMailAddress: true,
      rssviewOptions: [{ text: this.t('なし'), value: false }, { text: this.t('あり'), value: true }],
      rssview: null,
      newRssview: null,
      dplusLink: null,
      dplusUrl: DPLUS,
      isInitializing: true,
      tooltipAccessToken: false,
      deleteAllAccounts: true,
    };
  },
  mixins: [AuthUtil, Utils],
  components: {
    SelectOrganization,
    LoadingScreen,
  },
  async created() {
    this.admin = await this.isAdmin();
    this.disabledChangeMailAddress = await this.isSns();
    this.subscriberType = await this.getSubscriberType(false);
    this.rssview = (await UserSettings.getValue(KEY_RSSVIEW, 'true')) === 'true';
    this.newRssview = this.rssview;
    this.dplusLink = await this.fetchDplusLink();
    if (this.$route.query.checkout === '1') {
      await this.checkout();
    }
    else if (this.$route.query.checkout === '2') {
      await this.checkoutYear();
    }
    else if (PRICEID_CAMPAIGN !== '' && this.$route.query.checkout === PRICEID_CAMPAIGN) {
      await this.checkoutCampaign();
    }
    this.isInitializing = false;
  },
  async mounted() {
    await this.loadUserAttributes();
  },
  methods: {
    async signOut() {
      await Auth.signOut();
      this.$router.go({ path: "login", force: true });
    },
    async showConfirmRemoveUser() {
      const subtype = await this.getSubscriberType(false);
      let message = this.t('退会すると全てのデータが削除され、メルマガも解除されます。<br />退会しますか？');
      if (subtype.name === 'EresaSubscriberWithApple') {
        message = this.t('【ご注意】課金の解除はアプリからも必ず行ってください。(こちらの退会だけでは課金は止まりません。)') + '<br />' + message;
      }
      this.$bvModal
        .msgBoxConfirm(this.$createElement('div', { domProps: { innerHTML: message } }), {
          title: this.t("退会確認"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.t("はい"),
          cancelTitle: this.t("いいえ"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (isOk) => {
          if (isOk) {
            await this.removeUserOk();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async removeUserOk() {
      this.isProcessing = true;
      try {
        await API.graphql(graphqlOperation(mutations.removeUser, { bymailaddress: this.deleteAllAccounts }));
        this.isProcessing = false;
        this.$refs.removeUserCompleted.show();
      }
      catch {
        this.isProcessing = false;
      }
    },
    async checkout() {
      this.isProcessing = true;
      try {
        const stripeDomain = this.$store.getters.getStripeDomain;
        const stripeInfo = this.$store.getters.getStripeInfo;
        const returnUrl = window.location.href.replace(/[?#].*$/, '');
        const successUrl = this.isComEresa() ? COM_SUCCESS_MONTH_URL : JP_SUCCESS_MONTH_URL;
        const portal = await API.graphql(graphqlOperation(queries.getStripeBillingPortal, { returnUrl, priceId: stripeInfo.priceIdMonth, successUrl, stripeDomain }));
        location.href = JSON.parse(portal.data.getStripeBillingPortal).url;
      }
      catch {
        this.isProcessing = false;
      }
    },
    async checkoutYear() {
      this.isProcessing = true;
      try {
        const stripeDomain = this.$store.getters.getStripeDomain;
        const stripeInfo = this.$store.getters.getStripeInfo;
        const returnUrl = window.location.href.replace(/[?#].*$/, '');
        const successUrl = this.isComEresa() ? COM_SUCCESS_YEAR_URL : JP_SUCCESS_YEAR_URL;
        const portal = await API.graphql(graphqlOperation(queries.getStripeBillingPortal, { returnUrl, priceId: stripeInfo.priceIdYear, successUrl, stripeDomain }));
        location.href = JSON.parse(portal.data.getStripeBillingPortal).url;
      }
      catch {
        this.isProcessing = false;
      }
    },
    async checkoutCampaign() {
      if (this.isComEresa()) {
        return;
      }
      // 期間限定キャンペーン用のリダイレクト（非表示）
      this.isProcessing = true;
      try {
        const stripeDomain = this.$store.getters.getStripeDomain;
        const stripeInfo = this.$store.getters.getStripeInfo;
        const returnUrl = window.location.href.replace(/[?#].*$/, '');
        const successUrl = JP_SUCCESS_YEAR_URL;
        const portal = await API.graphql(graphqlOperation(queries.getStripeBillingPortal, { returnUrl, priceId: stripeInfo.priceIdCampaign, successUrl, stripeDomain }));
        location.href = JSON.parse(portal.data.getStripeBillingPortal).url;
      }
      catch {
        this.isProcessing = false;
      }
    },
    async loadUserAttributes() {
      const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
      if (cognitoUser == void 0) {
        return;
      }
      const query = await API.graphql(graphqlOperation(queries.getUserInfo, { owner: cognitoUser.username }));
      const userInfo = query.data.getUserInfo;
      if (userInfo != void 0 && userInfo.organization != void 0) {
        this.organization = userInfo.organization;
      }
    },
    showSelectOrganization() {
      this.$refs.selectOrganization.show();
    },
    async organizationSelected(value) {
      this.organization = value;
      await API.graphql(graphqlOperation(mutations.updateUserAttributes, { organization: this.organization })).catch((err) => console.error(err));
    },
    getOrganizationText() {
      return this.$refs.selectOrganization.getOrganizationText(this.organization);
    },
    changeMailAddress() {
      this.$router.push({ name: 'ChangeMailAddress' });
    },
    changePassword() {
      this.$router.push({ name: 'ChangePassword' });
    },
    changeRssView() {
      this.newRssview = this.rssview;
      this.$refs.selectRssview.show();
    },
    async rssviewSelected() {
      if (this.newRssview === this.rssview) {
        return;
      }
      this.rssview = this.newRssview;
      await UserSettings.setValue(KEY_RSSVIEW, this.newRssview);
    },
    async fetchDplusLink() {
      if (!this.isComEresa() && this.subscriberType.subscriber) {
        const query = await API.graphql(graphqlOperation(queries.getDplusLink));
        if (query.data.getDplusLink != void 0) {
          return JSON.parse(query.data.getDplusLink);
        }
      }
      return void 0;
    },
    async linkToDplus() {
      this.dplusLink = await this.fetchDplusLink();
      if (this.dplusLink.registered_at == void 0) {
        // 新規
        window.open(`${this.dplusUrl}/lp/eresa_registration/?linkId=${this.dplusLink.link_id}`, '_blank');
      }
      else {
        // 登録済み
        window.open(`${this.dplusUrl}/app/`, '_blank');
      }
    },
    writeToClipboard(text) {
      navigator.clipboard.writeText(text).catch((e) => console.error(e));
      setTimeout(() => {
        this.$refs.tooltipAccessToken.show = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.title-sm {
  font-size: 20px;
}
</style>
