<template>
  <div class="sellerlist">
    <template v-if="subscriber === false">
      <header class="header bg-eresa text-center w-100 locktitle" style="opacity: 0.6">
        <span>{{ t('出品者一覧') }}<font-awesome-icon icon="fa-solid fa-lock" style="color: #fff;" class="ml-2" /></span>
        <lock-pro :nolink="nolink" noicon></lock-pro>
      </header>
    </template>
    <template v-else-if="disabled === true">
      <header class="header bg-eresa text-center w-100">
        <span>{{ t('出品者一覧') }}</span>
      </header>
    </template>
    <header v-else class="header bg-eresa text-center w-100" v-b-toggle="collapseid" @click="changeIcon">
      <b-icon :icon="headerIcon"></b-icon>
      <span class="ml-4 mr-4">{{ t('出品者一覧') }}</span>
      <b-icon :icon="headerIcon"></b-icon>
    </header>

    <template v-if="subscriber != false">
      <b-collapse :id="collapseid">
        <div class="text-right small">
          <b-spinner v-if="loading" variant="primary" small></b-spinner>
          <span v-if="condition === CONDITION_ALL" class="cond text-white ml-2 px-1">{{ t('すべて') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_ALL)" class="text-eresa ml-2">{{ t('すべて') }}</a>
          <span v-if="condition === CONDITION_NEW" class="cond text-white ml-2 px-1">{{ t('新品') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_NEW)" class="text-eresa ml-2">{{ t('新品') }}</a>
          <span v-if="condition === CONDITION_ALMOSTNEW" class="cond text-white ml-2 px-1">{{ t('ほぼ新品') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_ALMOSTNEW)" class="text-eresa ml-2">{{ t('ほぼ新品') }}</a>
          <span v-if="condition === CONDITION_VERYGOOD" class="cond text-white ml-2 px-1">{{ t('非常に良い') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_VERYGOOD)" class="text-eresa ml-2">{{ t('非常に良い') }}</a>
          <span v-if="condition === CONDITION_GOOD" class="cond text-white ml-2 px-1">{{ t('良い') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_GOOD)" class="text-eresa ml-2">{{ t('良い') }}</a>
          <span v-if="condition === CONDITION_ACCEPTABLE" class="cond text-white ml-2 px-1">{{ t('可') }}</span><a v-else href="javascript:void(0)" @click="selectCondition(CONDITION_ACCEPTABLE)" class="text-eresa ml-2">{{ t('可') }}</a>
        </div>

        <div v-if="list != void 0 && list.length <= 0">
          <div class="alert alert-info" role="alert">{{ t('出品はありませんでした。') }}</div>
        </div>
        <div v-if="item.productType != void 0 && item.productType == 5">
          <div class="alert alert-warning" role="alert">{{ t('親ASINです。バリエーションを選択してください。') }}</div>
        </div>
        <template v-else>
          <!-- PC -->
          <div class="d-none d-md-block">
            <div class="seller-table">
              <div class="th bg-eresa text-white">{{ t('商品価格+配送料') }}</div>
              <div class="th bg-eresa text-white">{{ t('コンディション') }}</div>
              <div class="th bg-eresa text-white">{{ t('販売/出品') }}</div>
              <div class="th bg-eresa text-white">{{ t('出荷元') }}</div>
              <template v-if="list != void 0">
                <template v-for="(row, i) in (showLimited ? list.slice(0, LIMITED_ROWS) : list)">
                  <div :key="`price${i}`">
                    <div class="font-weight-bold">{{ CONDITIONS[row.condition] }}</div>
                    <div>{{ price(getCurrentPrice(row.offerCSV)) }}</div>
                    <div v-if="getCurrentShipping(row.offerCSV) === 0">{{ t('通常配送無料') }}</div>
                    <div v-else>
                      + {{ price(getCurrentShipping(row.offerCSV)) }}{{ t('(配送料)') }}
                    </div>
                  </div>
                  <div :key="`cond${i}`">
                    <div v-if="row.conditionComment == void 0">-</div>
                    <div v-else>
                      <div v-if="showAllCommentFlagMap[row.offerId] === true">
                        {{ row.conditionComment }}
                      </div>
                      <div v-else>
                        {{ row.conditionComment.substr(0, 100) }}
                        <b-button
                          v-if="row.conditionComment.length >= 100"
                          variant="link"
                          @click="$set(showAllCommentFlagMap, row.offerId, true)">
                            {{ t('続きを読む') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div :key="`seller${i}`">
                    <div v-if="row.seller != void 0">
                      <a v-if="nolink == void 0"
                        :href="`https://www.amazon${getDomainName(item.domainId)}/sp?_encoding=UTF8&asin=${asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${row.seller.sellerId}`"
                        target="_blank"
                        >{{ row.seller.sellerName }}</a
                      >
                      <div v-else>{{ row.seller.sellerName }}</div>
                      <div class="d-inline ml-2" >
                        <img v-if="getSellerCountryCode(row.seller) === 'US'" class="flag" src="/img/flag_com.png" alt />
                        <img v-else-if="getSellerCountryCode(row.seller) === 'JP'" class="flag border" src="/img/flag_jp.png" alt />
                        <img v-else-if="getSellerCountryCode(row.seller) === 'CN'" class="flag" src="/img/flag_cn.png" alt />
                        <div v-else-if="getSellerCountryCode(row.seller) != void 0" class="country-code">{{ getSellerCountryCode(row.seller) }}</div>
                      </div>
                      <div>
                        {{ t('評価') }}: {{ getCurrentRating(row.seller.csv) }} ({{
                          getCurrentRatingCount(row.seller.csv)
                        }}{{ t('件') }})
                      </div>
                      <div class="d-flex">
                        {{ t('{0}%の高評価', [row.seller.currentRating]) }}
                        <b-button v-if="nolink == void 0" variant="white" class="border py-0 px-2 ml-2 mb-1" :title="t('セラーリサーチ')" @click="sellerResearch(row.seller.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
                      </div>
                    </div>
                  </div>
                  <div :key="`stock${i}`">
                    <div v-if="row.seller != void 0">
                      <div v-if="row.isFBA">Amazon</div>
                      <div v-else>{{ row.seller.sellerName }}</div>
                      <b-badge variant="danger" v-if="row.isPrime" class="mt-2 mr-2">Prime</b-badge>
                      <b-badge variant="danger" v-if="row.shipsFromChina" class="mt-2 mr-2">{{ t('中国から発送') }}</b-badge>
                      <div v-if="row.isShippable">{{ t('在庫あり') }}</div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>

          <!-- mobile -->
          <div class="d-block d-md-none">
            <div class="seller-table-m">
              <div class="th bg-eresa text-white">{{ t('商品価格+配送料') }}<br/>{{ t('販売/出品') }}</div>
              <div class="th bg-eresa text-white">{{ t('コンディション') }}<br/>{{ t('出荷元') }}</div>
              <template v-if="list != void 0">
                <template v-for="(row, i) in (showLimited ? list.slice(0, LIMITED_ROWS) : list)">
                  <div :key="`price${i}`">
                    <div class="font-weight-bold">{{ CONDITIONS[row.condition] }}</div>
                    <div>{{ price(getCurrentPrice(row.offerCSV)) }}</div>
                    <div v-if="getCurrentShipping(row.offerCSV) === 0">{{ t('通常配送無料') }}</div>
                    <div v-else>
                      + {{ price(getCurrentShipping(row.offerCSV)) }}{{ t('(配送料)') }}
                    </div>
                  </div>
                  <div :key="`cond${i}`">
                    <div v-if="row.conditionComment == void 0">-</div>
                    <div v-else>
                      <div v-if="showAllCommentFlagMap[row.offerId] === true">
                        {{ row.conditionComment }}
                      </div>
                      <div v-else>
                        {{ row.conditionComment.substr(0, 100) }}
                        <b-button
                          v-if="row.conditionComment.length >= 100"
                          variant="link"
                          class="text-m"
                          @click="$set(showAllCommentFlagMap, row.offerId, true)">
                            {{ t('続きを読む') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div :key="`seller${i}`" class="bottom">
                    <div v-if="row.seller != void 0">
                      <a v-if="nolink == void 0"
                        :href="`https://www.amazon${getDomainName(item.domainId)}/sp?_encoding=UTF8&asin=${asin}&isAmazonFulfilled=0&ref_=olp_merch_name_1&seller=${row.seller.sellerId}`"
                        target="_blank"
                        >{{ row.seller.sellerName }}</a
                      >
                      <div v-else>{{ row.seller.sellerName }}</div>
                      <div class="d-inline ml-2" >
                        <img v-if="getSellerCountryCode(row.seller) === 'US'" class="flag" src="/img/flag_com.png" alt />
                        <img v-else-if="getSellerCountryCode(row.seller) === 'JP'" class="flag border" src="/img/flag_jp.png" alt />
                        <img v-else-if="getSellerCountryCode(row.seller) === 'CN'" class="flag" src="/img/flag_cn.png" alt />
                        <div v-else-if="getSellerCountryCode(row.seller) != void 0" class="country-code">{{ getSellerCountryCode(row.seller) }}</div>
                      </div>
                      <div>
                        {{ t('評価') }}: {{ getCurrentRating(row.seller.csv) }} ({{
                          getCurrentRatingCount(row.seller.csv)
                        }}{{ t('件') }})
                      </div>
                      <div class="d-flex">
                        {{ t('{0}%の高評価', [row.seller.currentRating]) }}
                        <b-button v-if="nolink == void 0" variant="white" class="border py-0 px-2 ml-2 mb-1" :title="t('セラーリサーチ')" @click="sellerResearch(row.seller.sellerId)"><b-icon icon="search" class="searchicon"></b-icon></b-button>
                      </div>
                    </div>
                  </div>
                  <div :key="`stock${i}`" class="bottom">
                    <div v-if="row.seller != void 0">
                      <div v-if="row.isFBA">Amazon</div>
                      <div v-else>{{ row.seller.sellerName }}</div>
                      <b-badge variant="danger" v-if="row.isPrime" class="mt-2 mr-2">Prime</b-badge>
                      <b-badge variant="danger" v-if="row.shipsFromChina" class="mt-2 mr-2">{{ t('中国から発送') }}</b-badge>
                      <div v-if="row.isShippable">{{ t('在庫あり') }}</div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>

        <div class="text-right">
          <b-button v-if="list != void 0 && showLimited && list.length > LIMITED_ROWS" variant="link" @click="showLimited = false" class="readmore">{{ t('…さらに表示▼') }}</b-button>
        </div>
      </b-collapse>
    </template>
  </div>
</template>

<script>
import { I18n } from 'aws-amplify';
import LockPro from '@/components/LockPro.vue';
import Utils from '@/mixins/utils';
import { EresaApis } from '@/mixins/EresaApis';

const getConditions = () => ({
  0: '-',
  1: I18n.get('新品'),
  2: I18n.get('中古商品 - ほぼ新品'),
  3: I18n.get('中古商品 - 非常に良い'),
  4: I18n.get('中古商品 - 良い'),
  5: I18n.get('中古商品 - 可'),
  6: I18n.get('再生品'),
  7: I18n.get('コレクター商品 - ほぼ新品'),
  8: I18n.get('コレクター商品 - 非常に良い'),
  9: I18n.get('コレクター商品 - 良い'),
  10:I18n.get('"コレクター商品 - 可'),
  11:I18n.get('"レンタル'),
});

export default {
  name: 'ProductSellerList',
  components: {
    LockPro,
  },
  props: ['item', 'subscriber', 'collapsekey', 'nolink', 'disabled'],
  mixins: [Utils],
  data() {
    return {
      LIMITED_ROWS: 3, //初期表示件数
      CONDITIONS: getConditions(),
      CONDITION_ALL: 0,
      CONDITION_NEW: 1,
      CONDITION_ALMOSTNEW: 2,
      CONDITION_VERYGOOD: 3,
      CONDITION_GOOD: 4,
      CONDITION_ACCEPTABLE: 5,
      collapseid: `sellerlist-${this.collapsekey ?? this.item.asin}`,
      headerIcon: 'chevron-down',
      allList: null,
      list: null,
      loading: false,
      showLimited: true,
      condition: 0,
      showAllCommentFlagMap: {},
    }
  },
  watch: {
    item() {
      this.allList = null;
      this.list = null;
      this.limitedList = null;
    },
  },
  mounted() {
    this.allList = null;
    this.list = null;
    this.limitedList = null;
  },
  methods: {
    async changeIcon() {
      this.headerIcon = this.headerIcon === 'chevron-down' ? 'chevron-up' : 'chevron-down';
      this.showLimited = true;
      if (this.allList == void 0) {
        if (this.item.liveOffersOrder != void 0) {
          this.allList = this.item.liveOffersOrder.map(idx => this.item.offers[idx]);
        }
        else {
          this.allList = [];
        }
        this.setSellerInfo();
      }
      if (this.list == void 0) {
        await this.selectCondition(this.condition);
      }
      for (const row of this.list) {
        this.$set(this.showAllCommentFlagMap, row.offerId, false);
      }
    },
    async selectCondition(cond) {
      this.loading = true;
      this.condition = cond;
      this.showLimited = true;
      await new Promise(() => setTimeout(async () => {
        await this.makeList(this.condition);
        this.loading = false;
      }, 100));
    },
    async makeList(cond) {
      // 出品者情報取得（詳細画面から使用されるので出品者情報取得済みの前提）
      this.list = [];
      if (cond === this.CONDITION_ALL) {
        this.list = this.allList;
      }
      else {
        this.list = this.allList.filter(offer => offer.condition === cond);
      }
    },
    async setSellerInfo() {
      for (const offer of this.allList) {
        const seller = await EresaApis.getSeller(offer.sellerId, false, this.item.domainId);
        this.$set(offer, 'seller', seller);
      }
    },
    getCurrentPrice(csv) {
      if (!csv) return "-";
      return csv[csv.length - 2];
    },
    getCurrentShipping(csv) {
      if (!csv) return "-";
      return csv[csv.length - 1];
    },
    getCurrentRating(csv) {
      if (!csv || !csv[0]) return "-";
      return csv[0][csv[0].length - 1] / 20;
    },
    getCurrentRatingCount(csv) {
      if (!csv || !csv[1]) return "-";
      return csv[1][csv[1].length - 1];
    },
    getSellerCountryCode(seller) {
      const address = seller.address;
      const code = address == void 0 ? null : address[address.length - 1];
      return code;
    },
    price(val) {
      if (this.item.domainId == this.$store.getters.getComDomain) {
        const opt = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return `$${val !== null && val >= 0 ? Number(val / 100).toLocaleString(undefined, opt) : "-"}`;
      }
      return this.t('{0}円', [`${val !== null && val >= 0 ? Number(val).toLocaleString() : "-"}`]);
    },
    sellerResearch(sellerid) {
      this.$router.push({ name: "SellerResearch", params: { sellerid }, query: { domain: this.item.domainId } });
    },
  },
};
</script>

<style scoped>
.header {
  font-weight: bold;
  color: #fff;
}

.cond {
  background-color: #b9575e;
  border-radius: 3px;
}

.seller-table {
  display:grid;
  grid-template-columns: 16em 1fr 16em 16em;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.seller-table > div {
  border-bottom:1px solid lightgray;
  padding: 3px;
}

.seller-table-m {
  display:grid;
  grid-template-columns: 10em 1fr;
  border-left: 1px solid lightgray;
  border-top: 1px solid lightgray;
  border-right: 1px solid lightgray;
  font-size: 0.75rem;
}

.seller-table-m > div {
  padding: 3px;
}

.seller-table-m > div.bottom {
  margin-top: 2px;
  border-bottom:1px solid lightgray;
}

.text-m {
  font-size: 0.75rem;
}

.searchicon {
  width: 10px;
}

.country-code {
  display: inline;
  font-size: 0.75em;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 2px;
}

.flag {
  width: 18px;
  height: 12px;
  object-fit: cover;
}

@media (max-width: 575.98px) {
  .sellerlist {
    font-size: 10pt;
  }
  .readmore {
    font-size: 10pt;
  }
}
</style>
