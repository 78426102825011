export default {
  "年間機能管理": "Annual functional management",
  "年間機能を強制解放したいメールアドレスを入力してください。": "Please enter the email address with which you wish to force the release of the annual feature.",
  "メールアドレス": "Email Address",
  "会員種別": "Membership type",
  "登録": "Register",
  "メールアドレス一覧：": "Email address list:",
  "選択されたメールアドレスの年間機能を解除": "Cancel the annual function for the selected e-mail address",
  "選択": "Selection",
  "登録します。よろしいですか？": "Do you want to register?",
  "解除": "Cancel",
  "解除します。よろしいですか？": "Would you like to cancel?",
  "会員種別を表示": "Show membership type",
  "不明なユーザー（退会している可能性があります）": "Unknown user (may have resigned from membership)",
  "管理用ユーザー": "Administrative user.",
  "ERESA PRO会員（永久会員）": "ERESA PRO member (permanent membership)",
  "ERESA PRO会員（有料会員）": "ERESA PRO member (paid membership)",
  "ERESA PRO会員（アプリ版から課金された有料会員）": "ERESA PRO member (paid membership charged from the app version)",
  "無料会員": "Free membership",
  "メールアドレスは登録済みです。": "Your email address is already registered.",
  "ERESA未登録のメールアドレスです。": "This is an ERESA unregistered email address.",
}
