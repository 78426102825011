<template>
  <div class="page-content">
    <div class="container">
      <manual-link>{{ t('お知らせ設定') }}</manual-link>

      <label class="d-flex flex-wrap mt-2">{{ t('公開設定') }}
        <b-form-radio-group v-model="noticeSetting.public" class="ml-4">
          <b-form-radio :value="true">{{ t('公開') }}</b-form-radio>
          <b-form-radio :value="false">{{ t('非公開') }}</b-form-radio>
        </b-form-radio-group>
      </label>
      <label class="d-flex flex-wrap mt-2">{{ t('タイトル') }}
        <b-form-input v-model="noticeSetting.title" placeholder="Enter title"></b-form-input>
      </label>
      <label class="d-flex flex-wrap mt-2">{{ t('リンク先') }}
        <b-form-input v-model="noticeSetting.link" placeholder="Enter the link URL"></b-form-input>
      </label>
      <b-button class="bg-eresa mt-4" @click="confirmUpdate()">{{ t('変更') }}</b-button>
      <b-spinner v-if="noticeUpdating" variant="primary" small></b-spinner>
    </div>

    <b-modal ref="updateConfirm" @ok="updateNotice" :title="t('変更')" :ok-title="t('変更')" :cancel-title="t('キャンセル')">
      {{ t('変更します。よろしいですか？') }}
    </b-modal>
  </div>
</template>

<script>
import ManualLink from '@/components/ManualLink.vue';
import Utils from '@/mixins/utils';
import AuthUtil from '@/mixins/authutil';
import { EresaApis } from '@/mixins/EresaApis';

export default {
  name: 'Notice',
  components: {
    ManualLink,
  },
  mixins: [Utils, AuthUtil],
  data() {
    return {
      noticeSetting: {},
      noticeUpdating: false,
    }
  },
  async mounted() {
    this.noticeSetting = await EresaApis.getEresaNotice();
    await this.validateAdmin();
  },
  methods: {
    confirmUpdate() {
      this.$refs.updateConfirm.show();
    },
    async updateNotice() {
      this.noticeUpdating = true;
      try {
        await EresaApis.updateEresaNotice(
          this.noticeSetting.public,
          this.noticeSetting.title,
          this.noticeSetting.link,
        );
      }
      finally {
        this.noticeUpdating = false;
      }
    },
  },
}
</script>

<style scoped>
</style>
