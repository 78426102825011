import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

export class UserSettings {
  /**
   * 全設定値を取得
   * @returns string
   */
  static async getValues() {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const query = await API.graphql(graphqlOperation(queries.getUserSettings, { owner: cognitoUser.username }));
      return query?.data?.getUserSettings?.settings;
    }
    return void 0;
  }

  /**
   * 値を取得
   * @param {string} key
   * @param {string} defaultValue
   * @returns
   */
  static async getValue(key, defaultValue) {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const settings = await UserSettings._loadSettings(cognitoUser.username) ?? [];
      return settings.find(item => item.key == key)?.value ?? defaultValue;
    }
    return defaultValue;
  }

  /**
   * 値を設定
   * @param {string} key
   * @param {string} value
   */
  static async setValue(key, value) {
    const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
    if (cognitoUser != void 0) {
      const settings = await UserSettings._loadSettings(cognitoUser.username);
      const update = settings !== null;
      const newSettings = (settings ?? []).filter(item => item.key !== key).map(item => ({ key: item.key, value: item.value, date: item.date }));
      const date = new Date().toISOString();
      newSettings.unshift({ key, value, date });
      await UserSettings._updateSettings(cognitoUser.username, newSettings, update);
    }
  }

  static async _loadSettings(username) {
    const query = await API.graphql(graphqlOperation(queries.getUserSettings, { owner: username }));
    if (query.data.getUserSettings != void 0) {
      return query.data.getUserSettings.settings ?? [];
    }
    return null;
  }

  static async _updateSettings(username, settings, update) {
    const input = { owner: username, settings };
    try {
      await API.graphql(graphqlOperation(update ? mutations.updateUserSettings : mutations.createUserSettings, { input }));
    }
    catch(e) {
      console.error(e);
    }
  }
}
